export const router = [
  {
    title: 'navMenu.ecosystem',
    path: '/ecosystem/token-list',
    parentPath: '/ecosystem',
    children: [
      {
        title: 'navMenu.tokenList',
        path: '/ecosystem/token-list',
        link: '',
        disabled: false,
      },
      // {
      //   title: 'navMenu.supportedChains',
      //   path: '/ecosystem/supported-chains',
      //   link: '',
      //   disabled: false,
      // },
    ],
  },
  {
    title: 'navMenu.features',
    path: '/features',
    parentPath: '/features',
    children: [],
  },
  {
    title: 'navMenu.aboutUs',
    path: '/about-us/roadmap',
    parentPath: '/about-us',
    children: [
      {
        title: 'navMenu.roadmap',
        path: '/about-us/roadmap',
        link: '',
        disabled: false,
      },
      {
        title: 'navMenu.whyUs',
        path: '/about-us/technology',
        link: '',
        disabled: false,
      },
      {
        title: 'navMenu.privacyPolicy',
        path: '/about-us/privacy-policy',
        link: '',
        disabled: false,
      },
      {
        title: 'navMenu.termsConditions',
        path: '/about-us/terms-conditions',
        link: '',
        disabled: false,
      },
      {
        title: 'navMenu.faq',
        path: '/about-us/faq',
        link: '',
        disabled: false,
      },
    ],
  },
];

export const languages = [
  {
    title: 'English',
    lang: 'enUS',
  },
];
