import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
export interface GlobalState {
  isLoading: boolean;
  toastInfo: {
    open: boolean;
    type: string;
  };
  countryCode: string[];
}

const initialState: GlobalState = {
  isLoading: false,
  toastInfo: {
    open: false,
    type: 'success',
  },
  countryCode: [],
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showLoad: (state) => {
      state.isLoading = true;
    },
    hideLoad: (state) => {
      state.isLoading = false;
    },
    updateToastInfo: (state, action) => {
      const { toastInfo } = action.payload;
      state.toastInfo = { ...state.toastInfo, ...toastInfo };
    },
    updateCountryCode: (state, action) => {
      const { countryCode } = action.payload;
      state.countryCode = countryCode;
    },
  },
});

export const { showLoad, hideLoad, updateToastInfo, updateCountryCode } = globalSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default globalSlice.reducer;
