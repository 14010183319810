import { createTheme } from '@mui/material/styles';

interface TextColor {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
}

interface BgColor {
  bg1: string;
  bg2: string;
  bg3: string;
  bg4: string;
  bg5: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
  }
}

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    textColor: TextColor;
    bgColor: BgColor;
  }
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
  }
}

export const theme = {
  palette: {
    primary: {
      main: '#0096C7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#282828',
      contrastText: '#F4B91E',
    },
    info: {
      main: '#fc34001a',
      contrastText: '#FC3400',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      text1: '#454545',
      text2: '#8B8B8B',
      text3: '#0096C7',
      text4: 'red',
      text5: '#8A899E',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      bg1: '#15253B',
      bg2: '#E9E9E9',
      bg3: '#7FF3F9',
      bg4: '#303030',
      bg5: '#A1A1A1',
    },
  },
  typography: {
    fontFamily: 'Aeonik Regular',
    body1: {
      fontWeight: 500,
      fontSize: 28,
      color: '#000',
    },
    body2: {
      fontWeight: 400,
      fontSize: 18,
      color: '#717184',
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      color: '#fff',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: any }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              textTransform: 'none' as any,
              '&.Mui-disabled': {
                opacity: 0.65,
                backgroundColor: '#F4B91E',
                color: '#fff',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              textTransform: 'none' as any,
              '&.Mui-disabled': {
                opacity: 0.65,
                backgroundColor: '#F4B91E',
                color: '#fff',
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              textTransform: 'none' as any,
              '&.Mui-disabled': {
                opacity: 0.65,
                backgroundColor: '#F4B91E',
                color: '#fff',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              '&:hover': {
                backgroundColor: '#3d3c3c',
                boxShadow: 'unset',
              },
              '&.Mui-disabled': {
                opacity: 0.65,
                backgroundColor: '#282828',
                color: '#F4B91E',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'info' && {
              '&.Mui-disabled': {
                opacity: 0.65,
                backgroundColor: '#fc34001a',
                color: '#FC3400',
              },
            }),
        }),
      },
    },
  },
  textColor: {
    text1: '#454545',
    text2: '#8B8B8B',
    text3: '#0096C7',
    text4: 'red',
    text5: '#8A899E',
  },
  bgColor: {
    bg1: '#15253B',
    bg2: '#E9E9E9',
    bg3: '#7FF3F9',
    bg4: '#303030',
    bg5: '#A1A1A1',
  },
};

export default createTheme({
  ...theme,
});
