import { styled } from '@mui/material';
import Header from 'components/Header';
import TokenListContent from 'components/TokenListContent';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  overflowX: 'hidden',
  gridTemplateRows: `${theme.spacing(9)} 1fr`,
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'header'
    'tokenList'
  `,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(8)} 1fr`,
  },
}));

const HeaderBox = styled('div')({
  gridArea: 'header',
  background: '#0096C7',
});

const TokenList = () => {
  return (
    <MainContainer>
      <Header />
      <HeaderBox />
      <TokenListContent />
    </MainContainer>
  );
};

export default TokenList;
