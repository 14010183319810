import apt from 'assets/tokenList/apt.png';
import bnb from 'assets/tokenList/bnb.png';
import cro from 'assets/tokenList/cro.png';
import eth from 'assets/tokenList/eth.png';
import ftm from 'assets/tokenList/ftm.png';
import keth from 'assets/tokenList/keth.png';
import oeth from 'assets/tokenList/oeth.png';
import okx from 'assets/tokenList/okx.png';
import ss from 'assets/tokenList/ss.png';
import tomo from 'assets/tokenList/tomo.png';
import akash from 'assets/tokenList/akash.png';
import algo from 'assets/tokenList/algo.png';
import astar from 'assets/tokenList/astar.png';
import bch from 'assets/tokenList/bch.png';
import btc from 'assets/tokenList/btc.png';
import cfx from 'assets/tokenList/cfx.png';
import cosmos from 'assets/tokenList/cosmos.png';
import cryptoorg from 'assets/tokenList/cryptoorg.png';
import fetch from 'assets/tokenList/fetch.png';
import juno from 'assets/tokenList/juno.png';
import osmosis from 'assets/tokenList/osmosis.png';
import secret from 'assets/tokenList/secret.png';
import kusama from 'assets/tokenList/kusama.png';
import doge from 'assets/tokenList/doge.png';
import polkadot from 'assets/tokenList/polkadot.png';
import gno from 'assets/tokenList/gno.png';

export const lists = [
  {
    title: 'ETH (Ethereum)',
    icon: eth,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'OKX',
        chainIcon: okx,
      },
      {
        name: 'TOMO',
        chainIcon: tomo,
      },
      {
        name: 'FTM',
        chainIcon: ftm,
      },
      {
        name: 'SETH',
        chainIcon: ss,
      },
    ],
  },
  {
    title: 'ALGO (Algorand)',
    icon: algo,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'OKX',
        chainIcon: okx,
      },
    ],
  },
  {
    title: 'BCH (Bitcoin Cash)',
    icon: bch,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'OKX',
        chainIcon: okx,
      },
      {
        name: 'TOMO',
        chainIcon: tomo,
      },
      {
        name: 'FTM',
        chainIcon: ftm,
      },
      {
        name: 'SETH',
        chainIcon: ss,
      },
    ],
  },
  {
    title: 'BTC (Bitcoin)',
    icon: btc,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'OKX',
        chainIcon: okx,
      },
      {
        name: 'TOMO',
        chainIcon: tomo,
      },
      {
        name: 'FTM',
        chainIcon: ftm,
      },
      {
        name: 'SETH',
        chainIcon: ss,
      },
    ],
  },
  {
    title: 'CFX (Conflux)',
    icon: cfx,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Akash (Akash)',
    icon: akash,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'OETH',
        chainIcon: oeth,
      },
      {
        name: 'CRO',
        chainIcon: cro,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'DOGE(Dogecoin)',
    icon: doge,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Cosmos',
    icon: cosmos,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Crypto.org',
    icon: cryptoorg,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'BNB',
        chainIcon: bnb,
      },
      {
        name: 'APT',
        chainIcon: apt,
      },
    ],
  },
  {
    title: 'Fetch.ai',
    icon: fetch,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Juno',
    icon: juno,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Osmosis',
    icon: osmosis,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'Secret Network',
    icon: secret,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'ASTR',
    icon: astar,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'KSM',
    icon: kusama,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'DOT',
    icon: polkadot,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
  {
    title: 'GNO',
    icon: gno,
    collapse: false,
    children: [
      {
        name: 'ETH',
        chainIcon: eth,
      },
      {
        name: 'KETH',
        chainIcon: keth,
      },
    ],
  },
];
