import React from 'react';
import { styled, Box, Stack, Button, Avatar, Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useI18n } from 'react-simple-i18n';
import logo from 'assets/header/logo.png';
import logoWhite from 'assets/header/logoWhite.png';
import mobile1 from 'assets/home/mobile1.png';
import { ReactComponent as Lang } from 'assets/header/lang.svg';
import { ReactComponent as LangWhite } from 'assets/header/langWhite.svg';
import { ReactComponent as MenuIcon } from 'assets/header/menu.svg';
import { ReactComponent as MenuWhite } from 'assets/header/menuWhite.svg';
import Menu from 'components/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import Container from '@mui/material/Container';
import useBreakpoint from 'hooks/useBreakpoint';

const Bar = styled(Box)<{ scrolltop: number; ishome: string }>(({ theme, scrolltop, ishome }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.spacing(9),
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  // background: isHome ? `rgba(255, 255, 255, ${scrolltop})` : '#0096C7',
  background: ishome ? '#fff' : '#0096C7',
  zIndex: 2,
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(8),
    // boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',
  },
}));

const LogoBox = styled(Button)<{ ishome: string }>(({ theme, ishome }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textTransform: 'unset',
  gap: theme.spacing(1),
  background: 'transparent !important',
  '& p': {
    fontSize: 27,
    fontWeight: 700,
    color: ishome ? '#000' : '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    '& p': {
      fontSize: 20,
    },
    '& .drawerMenu': {
      color: '#000',
    },
  },
}));

const Logo = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4.5),
  width: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

const ContactUsBtn = styled(Button)<{ ishome: string }>(({ theme, ishome }) => ({
  height: theme.spacing(6.5),
  width: theme.spacing(22),
  fontSize: 16,
  fontWeight: 700,
  color: ishome ? '#0096C7' : '#fff',
  textTransform: 'unset',
  borderColor: ishome ? '#0096C7' : '#fff',
  borderWidth: '1.5px',
  borderRadius: theme.spacing(1.5),
  '&:hover': {
    borderWidth: '1.5px',
    borderColor: ishome ? '#0096C7' : '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(4),
    padding: theme.spacing(0, 2),
  },
}));

const HeaderRight = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(8.5),
}));

const Header = ({ displayHeader, isHome }: { displayHeader?: boolean; isHome?: boolean }) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useBreakpoint();
  const [open, setOpen] = React.useState(false);
  const [scrollHeight, setScrollHeight] = React.useState(0);

  React.useEffect(() => {
    function handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      setScrollHeight(scrollTop);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const composeEmail = () => {
    const recipient = 'bussiness@lynk.social';
    const subject = encodeURIComponent('');
    const body = encodeURIComponent('');
    const url = 'https://mail.google.com/mail/?view=cm&to=' + recipient + '&su=' + subject + '&body=' + body;
    window.open(url);
  };

  return displayHeader ? null : (
    <Bar scrolltop={scrollHeight > 72 ? 1 : scrollHeight / 72} ishome={isHome ? 'isHome' : ''}>
      <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} gap={7.5}>
          <LogoBox disableRipple ishome={isHome ? 'isHome' : ''} onClick={() => navigate('/home')}>
            <Logo src={isHome ? logo : logoWhite} variant="square" />
            <Typography>LYNK</Typography>
          </LogoBox>
          <Menu isWeb={true} isHome={isHome} />
        </Stack>
        {isMobile ? (
          <IconButton disableRipple onClick={() => setOpen(true)}>
            {isHome ? <MenuIcon /> : <MenuWhite />}
          </IconButton>
        ) : (
          <HeaderRight>
            <Stack gap={0.5} direction={'row'} alignItems={'center'}>
              {isHome ? <Lang /> : <LangWhite />}
              <Typography fontSize={16} color={isHome ? 'unset' : '#fff'}>
                English
              </Typography>
            </Stack>
            <ContactUsBtn onClick={composeEmail} ishome={isHome ? 'isHome' : ''} variant="outlined">
              {t('footer.contactUs')}
            </ContactUsBtn>
          </HeaderRight>
        )}
        <Dialog
          fullScreen
          open={open}
          sx={{
            '& .MuiDialog-paper': {
              background: '#fff',
              backgroundImage: `url(${mobile1})`,
              backgroundPosition: 'center bottom',
              backgroundSize: '323px 330px',
              backgroundRepeat: 'no-repeat',
            },
          }}
        >
          <Container>
            <Stack
              height={64}
              direction={'row'}
              alignItems={'center'}
              bgcolor={'#fff'}
              justifyContent={'space-between'}
            >
              <LogoBox ishome={isHome ? 'isHome' : ''} disableRipple onClick={() => navigate('/home')}>
                <Logo src={logo} variant="square" />
                <Typography className="drawerMenu">LYNK</Typography>
              </LogoBox>
              <IconButton disableRipple color="inherit" onClick={() => setOpen(false)}>
                <CloseIcon sx={{ color: '#0096C7' }} />
              </IconButton>
            </Stack>
          </Container>
          <Menu />
        </Dialog>
      </Container>
    </Bar>
  );
};

export default Header;
