import { useEffect } from 'react';
import { styled, Typography, Stack, Avatar } from '@mui/material';
import relationChart from 'assets/technology/relationChart.png';
import antiCensorship from 'assets/technology/antiCensorship.png';
import custodial from 'assets/technology/custodial.png';
import enhancedSecurity from 'assets/technology/enhancedSecurity.png';
import noDataStorage from 'assets/technology/noDataStorage.png';
import privacyProtection from 'assets/technology/privacyProtection.png';
import userFriendly from 'assets/technology/userFriendly.png';
import Container from '@mui/material/Container';
import { useI18n } from 'react-simple-i18n';
import useBreakpoint from 'hooks/useBreakpoint';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';
import PageFooter from 'components/PageFooter';
import { useNavigate, useLocation } from 'react-router-dom';

const TechnologyContentBox = styled('div')(({ theme }) => ({
  gridArea: 'technology',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: '#fff',
}));

const OverviewBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(4),
  '& .titleInfo': {
    fontSize: 14,
    color: '#0096C7',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    '& .MuiTypography-body1': {
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center',
    },
    '& .MuiTypography-body2': {
      fontSize: 16,
    },
    '& .titleInfo': {
      fontSize: 14,
      color: '#0096C7',
    },
    // height: theme.spacing(85),
    // padding: theme.spacing(1, 0, 0),
  },
}));

const MPCModel = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(4),
  '& .titleInfo': {
    fontSize: 14,
    color: '#0096C7',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    '& .title,.titleInfo': {
      fontSize: 24,
      fontWeight: 700,
      textAlign: 'center',
    },
    '& .MuiTypography-body2': {
      fontSize: 16,
    },
    '& .titleInfo': {
      fontSize: 14,
      color: '#0096C7',
    },
  },
}));

const RelationChartBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 0, 9.5),
  alignItems: 'center',
  position: 'relative',
  '& .text': {
    color: '#0096C7',
    fontSize: 16,
    fontWeight: 700,
    width: theme.spacing(30),
    lineHeight: theme.spacing(4),
    background: '#fff',
    textAlign: 'center',
  },
  '& .top': {
    position: 'absolute',
    top: '32px',
    right: '40%',
  },
  '& .left': {
    position: 'absolute',
    left: '165px',
    top: '54%',
  },
  '& .right': {
    position: 'absolute',
    right: '180px',
    top: '54%',
  },
  '& .bottom': {
    position: 'absolute',
    bottom: '75px',
    right: '40%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4.5, 0),
    '& .text': {
      fontSize: 12,
      zoom: 0.6,
      width: theme.spacing(30),
      lineHeight: theme.spacing(3.4),
      background: '#fff',
      textAlign: 'center',
    },
    '& .top': {
      position: 'absolute',
      top: '62px',
      right: 'calc(50% - 112px)',
    },
    '& .left': {
      position: 'absolute',
      left: '-20px',
      top: 'calc(57%)',
    },
    '& .right': {
      position: 'absolute',
      right: '-20px',
      top: 'calc(57%)',
    },
    '& .bottom': {
      position: 'absolute',
      bottom: '62px',
      right: 'calc(50% - 112px)',
    },
  },
}));

const RelationChart = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(96),
  height: theme.spacing(69),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
  },
}));

const MPCModelBottom = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(3),
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    // height: theme.spacing(85),
    // padding: theme.spacing(1, 0, 0),
  },
}));

const MPCModelItem = styled(Stack)(({ theme }) => ({
  width: 'calc(33.3% - 25px)',
  height: theme.spacing(36),
  gap: theme.spacing(3),
  padding: theme.spacing(3, 2),
  alignItems: 'center',
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100%)',
    padding: theme.spacing(2.5, 1.5),
    gap: theme.spacing(2.5),
    height: theme.spacing(31),
    '& .title': {
      fontSize: 18,
    },
    '& .des': {
      fontSize: 15,
    },
  },
}));

const MPCModelItemIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    // height: theme.spacing(85),
    // padding: theme.spacing(1, 0, 0),
  },
}));

const TechnologyContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useBreakpoint();

  const mpcModelList = [
    { icon: enhancedSecurity, title: t('solutionmal.enhancedSecurity'), des: t('solutionmal.enhancedSecurityDes') },
    { icon: custodial, title: t('solutionmal.custodial'), des: t('solutionmal.custodialDes') },
    { icon: privacyProtection, title: t('solutionmal.privacyProtection'), des: t('solutionmal.privacyProtectionDes') },
    { icon: antiCensorship, title: t('solutionmal.antiCensorship'), des: t('solutionmal.antiCensorshipDes') },
    { icon: userFriendly, title: t('solutionmal.userFriendly'), des: t('solutionmal.userFriendlyDes') },
    { icon: noDataStorage, title: t('solutionmal.noDataStorage'), des: t('solutionmal.noDataStorageDes') },
  ];

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          block: isMobile ? 'center' : 'start',
        });
      }
    }
  };

  useEffect(() => {
    if (pathname === '/about-us/faq') {
      scrollToAnchor('faq');
    }
  }, [pathname]);

  return (
    <TechnologyContentBox>
      <BaseHeader
        pageDes={t('solutionmal.pageDes')}
        title={t('solutionmal.title')}
        titleDes={t('solutionmal.titleDes')}
        className="technology"
      />
      <Container>
        <Stack gap={isMobile ? 4.5 : 9.5} pt={isMobile ? 4.5 : 9.5} pb={isMobile ? 4.5 : 9.5}>
          <OverviewBox>
            <Typography fontSize={40}>{t('solutionmal.overview')}</Typography>
            <Typography variant="body2">{t('solutionmal.overview1')}</Typography>
            <Typography variant="body2">{t('solutionmal.overview2')}</Typography>
            <Typography variant="body2">{t('solutionmal.overview3')}</Typography>
            <Typography variant="body2">{t('solutionmal.overview4')}</Typography>
          </OverviewBox>
          <OverviewBox>
            <Stack>
              <Typography className="titleInfo">{t('solutionmal.mpcWorksInfo')}</Typography>
              <Typography fontSize={40}>{t('solutionmal.mpcWorks')}</Typography>
            </Stack>
            <Typography variant="body2">{t('solutionmal.mpcWorksDes1')}</Typography>
            <Typography variant="body2">{t('solutionmal.mpcWorksDes2')}</Typography>
            <Typography variant="body2">{t('solutionmal.mpcWorksDes3')}</Typography>
            <Typography variant="body2">{t('solutionmal.mpcWorksDes4')}</Typography>
          </OverviewBox>
          <MPCModel>
            <Stack>
              <Typography className="titleInfo">{t('solutionmal.shredModelInfo')}</Typography>
              <Typography className="title">{t('solutionmal.shredModel')}</Typography>
            </Stack>
            <Typography variant="body2">{t('solutionmal.shredModelDes')}</Typography>
            <RelationChartBox>
              <RelationChart src={relationChart} />
              <Typography className="top text">{t('solutionmal.mobileDevice')}</Typography>
              <Typography className="bottom text">{t('solutionmal.blockchain')}</Typography>
              <Typography className="left text">{t('solutionmal.serviceProvider')}</Typography>
              <Typography className="right text">{t('solutionmal.trusteeService')}</Typography>
            </RelationChartBox>
            <MPCModelBottom>
              {mpcModelList.map((item) => (
                <MPCModelItem key={item.title}>
                  <MPCModelItemIcon src={item.icon} />
                  <Stack gap={1} alignItems={'center'}>
                    <Typography className="title" textAlign={'center'}>
                      {item.title}
                    </Typography>
                    <Typography className="des" variant="body2" textAlign={'center'}>
                      {item.des}
                    </Typography>
                  </Stack>
                </MPCModelItem>
              ))}
            </MPCModelBottom>
          </MPCModel>
        </Stack>
        <PageFooter id="faq" />
      </Container>
      <Footer />
    </TechnologyContentBox>
  );
};

export default TechnologyContent;
