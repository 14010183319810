import * as React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useBreakpoint from 'hooks/useBreakpoint';
import Fade from '@mui/material/Fade';

export default function BaseTooltip(props: TooltipProps): JSX.Element {
  const { children } = props;
  const isMobile = useBreakpoint();
  const [open, setOpen] = React.useState(false);

  return isMobile ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: '#fff',
                padding: 0,
                margin: 0,
                borderRadius: 1,
              },
            },
          }}
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          {...props}
        >
          {React.cloneElement(props.children, { onClick: () => setOpen(!open) })}
        </Tooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            bgcolor: '#1A1A1A',
            padding: 0,
            margin: 0,
            borderRadius: 1,
          },
        },
      }}
      TransitionComponent={Fade}
      placement="bottom-start"
      {...props}
    >
      {children}
    </Tooltip>
  );
}
