import { useEffect } from 'react';
import { styled, Typography, Stack, Avatar } from '@mui/material';
import { ReactComponent as Check } from 'assets/aboutUs/check.svg';
import { ReactComponent as CheckPrimary } from 'assets/aboutUs/checkPrimary.svg';
import { ReactComponent as ErrorPrimary } from 'assets/aboutUs/errorPrimary.svg';
import logo from 'assets/header/logoWhite.png';
import Container from '@mui/material/Container';
import { useI18n } from 'react-simple-i18n';
import useBreakpoint from 'hooks/useBreakpoint';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';
import PageFooter from 'components/PageFooter';
import RoadmapItem from 'components/RoadmapItem';
import { useNavigate, useLocation } from 'react-router-dom';

const AboutUsContentBox = styled('div')(() => ({
  gridArea: 'aboutUs',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: 'linear-gradient(180deg, #FFF 0%, #F6FBFE 92.19%)',
}));

const RoadmapBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(167.25),
  padding: theme.spacing(12, 0),
  width: '100%',
  gap: theme.spacing(8),
  alignItems: 'center',
  // background: '#fff',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: theme.spacing(4, 0),
    gap: theme.spacing(5.5),
  },
}));

const TitleTop = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  '& .title': {
    fontSize: 14,
    color: '#0096C7',
    fontWeight: 500,
  },
  '& .des': {
    fontSize: 40,
    fontWeight: 500,
  },
  '& .titleDes': {
    fontSize: 16,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0),
    '& .title': {
      fontWeight: 700,
      textAlign: 'center',
    },
    '& .des': {
      fontSize: 24,
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    '& .titleDes': {
      textAlign: 'center',
    },
  },
}));

const Roadmap = styled(Stack)(({ theme }) => ({
  flex: 1,
  width: '100%',
  flexDirection: 'row',
  backgroundImage: 'linear-gradient(180deg, #edededb3 0%, #edededb3 100%)',
  backgroundPosition: `center bottom`,
  backgroundSize: `4px 877px`,
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: `6px 75px`,
    backgroundSize: `2px calc(100% - 150px)`,
  },
}));

const RoadmapLeft = styled(Stack)(() => ({
  flex: 1,
}));

const RoadmapRight = styled(Stack)(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(22),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const TraditionalBox = styled(Stack)(() => ({
  width: '100%',
  background: '#F8FDFF',
}));

const TraditionalTableBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(156.25),
  width: '100%',
  gap: theme.spacing(8),
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5.5, 0, 0),
    gap: theme.spacing(5.5),
    height: theme.spacing(108),
    overflowX: 'scroll',
  },
}));

const TraditionalTable = styled(Stack)(({ theme }) => ({
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  borderRadius: '25px',
  padding: theme.spacing(0, 3),
  backgroundImage: 'linear-gradient(90deg, #E3EEFF 0%, #fff 100%)',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(116),
    boxShadow: '0px 2.9219px 14.60952px 0px rgba(193, 193, 193, 0.25)',
    borderRadius: '18px',
  },
}));

const Row = styled(Stack)<{ iswhite: string; col2: string; col1: string; row1: string }>(
  ({ theme, iswhite, col2, col1, row1 }) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: row1 ? '#0096C7' : col2 ? '#fff' : 'rgba(0, 0, 0, 0.65)',
    flex: col1 ? 4 : 3,
    fontWeight: row1 || col2 ? 700 : 400,
    fontSize: col2 ? 16 : 18,
    borderBottom: row1 ? (col2 ? '1px solid #24a4cf' : '1px solid #dee7f2') : col2 ? '1px solid #08a6d97a' : 'unset',
    backgroundColor: col1 ? '#E3EEFF' : col2 ? '#0096C7' : '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  }),
);

const Cell = styled(Stack)<{ row1: string }>(({ theme, row1 }) => ({
  // padding: '10px',
  height: row1 ? theme.spacing(15.5) : theme.spacing(9),
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    height: row1 ? theme.spacing(11) : theme.spacing(6.5),
  },
}));

const AboutUsContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useBreakpoint();
  const numRows = 11;
  const numColumns = 4;
  const rowDatas = [
    [t('aboutUs.tab1'), '', t('aboutUs.tab2'), t('aboutUs.tab3')],
    [t('aboutUs.tableTitle1'), 'succes', 'succes', 'succes'],
    [t('aboutUs.tableTitle2'), 'succes', 'error', 'error'],
    [t('aboutUs.tableTitle3'), t('aboutUs.noGas'), t('aboutUs.gasPayment'), t('aboutUs.noGas')],
    [t('aboutUs.tableTitle4'), t('aboutUs.noGas'), t('aboutUs.gasPayment'), t('aboutUs.noGas')],
    [t('aboutUs.tableTitle5'), 'succes', 'error', 'succes'],
    [t('aboutUs.tableTitle6'), t('aboutUs.normal'), t('aboutUs.high'), t('aboutUs.normal')],
    [t('aboutUs.tableTitle7'), t('aboutUs.normal'), t('aboutUs.partial'), t('aboutUs.all')],
    [t('aboutUs.tableTitle8'), t('aboutUs.allNetworks'), t('aboutUs.evmNetworks'), t('aboutUs.allNetworks')],
    [t('aboutUs.tableTitle9'), 'succes', 'succes', 'error'],
    [t('aboutUs.tableTitle10'), t('aboutUs.free'), t('aboutUs.free'), t('aboutUs.moderate')],
  ];

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    if (pathname === '/about-us/roadmap') {
      scrollToAnchor('roadmap');
    }
  }, [pathname]);

  const renderCell = (row: number, col: number, rowItem: string) => {
    const amount = col === 1 ? 3000 : col === 2 ? 5000 : 10000;
    return (
      <Row
        key={`cell-${row}-${col}`}
        col1={col === 0 ? 'col1' : ''}
        col2={col === 1 ? 'col1' : ''}
        row1={row === 0 ? 'row1' : ''}
        iswhite={row === 0 || (row === 8 && col !== 0) ? 'iswhite' : ''}
      >
        {rowItem === 'succes' ? (
          col === 1 ? (
            <Check />
          ) : (
            <CheckPrimary />
          )
        ) : rowItem === 'error' ? (
          <ErrorPrimary />
        ) : row === 0 && col === 1 ? (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Avatar src={logo} sx={{ width: 24, height: 24 }} variant="square" />
            <Typography fontSize={27} color={'#fff'}>
              LYNK
            </Typography>
          </Stack>
        ) : (
          rowItem
        )}
      </Row>
    );
  };

  const renderRow = (row: number) => {
    const cells = [];
    for (let col = 0; col < numColumns; col++) {
      cells.push(renderCell(row, col, rowDatas[row][col]));
    }
    return (
      <Cell key={`row-${row}`} row1={row === 0 ? 'row1' : ''}>
        {cells}
      </Cell>
    );
  };

  const rows = [];
  for (let row = 0; row < numRows; row++) {
    rows.push(renderRow(row));
  }

  return (
    <AboutUsContentBox>
      <BaseHeader
        pageDes={t('aboutUs.pageDes')}
        title={t('aboutUs.title')}
        titleDes={t('aboutUs.titleDes')}
        className="aboutUs"
      />
      <Container>
        <RoadmapBox id="roadmap">
          <TitleTop>
            <Typography className="title">{t('aboutUs.productRoadmap')}</Typography>
            <Typography className="des">{t('aboutUs.roadmapTitle')}</Typography>
          </TitleTop>
          <Roadmap>
            {isMobile ? (
              <RoadmapLeft>
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap01')}
                  title={t('aboutUs.roadmapTitle01')}
                  des={t('aboutUs.roadmapDes01')}
                />
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap02')}
                  title={t('aboutUs.roadmapTitle02')}
                  des={t('aboutUs.roadmapDes02')}
                />
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap03')}
                  title={t('aboutUs.roadmapTitle03')}
                  des={t('aboutUs.roadmapDes03')}
                />
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap04')}
                  title={t('aboutUs.roadmapTitle04')}
                  des={t('aboutUs.roadmapDes04')}
                />
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap05')}
                  title={t('aboutUs.roadmapTitle05')}
                  des={t('aboutUs.roadmapDes05')}
                />
              </RoadmapLeft>
            ) : (
              <RoadmapLeft>
                <RoadmapItem
                  quarter={t('aboutUs.roadmap01')}
                  title={t('aboutUs.roadmapTitle01')}
                  des={t('aboutUs.roadmapDes01')}
                />
                <RoadmapItem
                  quarter={t('aboutUs.roadmap03')}
                  title={t('aboutUs.roadmapTitle03')}
                  des={t('aboutUs.roadmapDes03')}
                />
                <RoadmapItem
                  quarter={t('aboutUs.roadmap05')}
                  title={t('aboutUs.roadmapTitle05')}
                  des={t('aboutUs.roadmapDes05')}
                />
              </RoadmapLeft>
            )}
            {isMobile ? null : (
              <RoadmapRight>
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap02')}
                  title={t('aboutUs.roadmapTitle02')}
                  des={t('aboutUs.roadmapDes02')}
                />
                <RoadmapItem
                  right={true}
                  quarter={t('aboutUs.roadmap04')}
                  title={t('aboutUs.roadmapTitle04')}
                  des={t('aboutUs.roadmapDes04')}
                />
              </RoadmapRight>
            )}
          </Roadmap>
        </RoadmapBox>
      </Container>
      <TraditionalBox>
        <TraditionalTableBox>
          <Container>
            <TitleTop>
              <Typography className="title">{t('aboutUs.whyUs')}</Typography>
              <Typography className="des">{t('aboutUs.whyUsTitle')}</Typography>
              <Typography className="titleDes" variant="body2">
                {t('aboutUs.whyUsDes')}
              </Typography>
            </TitleTop>
          </Container>
          {isMobile ? (
            <Stack
              maxWidth={'calc(100vw)'}
              sx={{
                '&::-webkit-scrollbar': { height: '9px' },
                '&::-webkit-scrollbar-track': { marginLeft: '16px', marginRight: '16px' },
              }}
              overflow={'scroll'}
              pl={2}
              pb={3}
              pr={2}
            >
              <TraditionalTable>{rows}</TraditionalTable>
            </Stack>
          ) : (
            <Container>
              <TraditionalTable>{rows}</TraditionalTable>
            </Container>
          )}
        </TraditionalTableBox>
      </TraditionalBox>
      <Container>
        <PageFooter isPadding={true} />
      </Container>
      <Footer />
    </AboutUsContentBox>
  );
};

export default AboutUsContent;
