import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';
export interface ApplicationState {
  readonly user: { readonly [key: string]: any };
  readonly lang: string;
}

const initialState: ApplicationState = {
  user: {},
  lang: 'enUS',
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const { user } = action.payload;
      state.user = Object.keys(user).length === 0 ? {} : { ...state.user, ...user };
    },
    updateLanguage: (state, { payload }) => {
      state.lang = payload;
    },
  },
});

export const { updateUser, updateLanguage } = applicationSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default applicationSlice.reducer;
