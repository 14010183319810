import { styled, Typography, Stack, Container } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import useBreakpoint from 'hooks/useBreakpoint';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const TermsConditionsContentBox = styled('div')(({ theme }) => ({
  gridArea: 'termsConditions',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: '#fff',
}));

const TermsConditionsBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(9.5, 0),
  '& .pageDes': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4.5, 0),
    '& .pageDes': {
      fontSize: 16,
    },
  },
}));

const InfoItem = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
  '& .title': {
    fontSize: 40,
    fontWeight: 500,
  },
  '& .des': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  '& li': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    '& .title': {
      fontSize: 24,
    },
    '& .des': {
      fontSize: 16,
    },
    '& li': {
      fontSize: 16,
    },
  },
}));

const TermsConditionsContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const isMobile = useBreakpoint();

  return (
    <TermsConditionsContentBox>
      <BaseHeader
        pageDes={''}
        title={t('termsConditions.title')}
        titleDes={`${t('termsConditions.lastUpdate')} 09/22/2023`}
        className="termsConditions"
      />
      <TermsConditionsBox>
        <Container>
          <Stack gap={isMobile ? 4.5 : 9.5}>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no1')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes1')}</Typography>
                <Typography className="des">{t('termsConditions.noDes2')}</Typography>
                <Typography className="des">{t('termsConditions.noDes3')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no2')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes4')}</Typography>
                <Typography className="des">{t('termsConditions.noDes5')}</Typography>
                <Typography className="des">{t('termsConditions.noDes6')}</Typography>
              </Stack>
            </InfoItem>
            {/* <InfoItem>
              <Typography className="title">{t('termsConditions.no3')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes7')}</Typography>
                <Typography className="des">{t('termsConditions.noDes8')}</Typography>
              </Stack>
            </InfoItem> */}
            <InfoItem>
              <Typography className="title">{t('termsConditions.no4')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes9')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no5')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes10')}</Typography>
                <Typography className="des">{t('termsConditions.noDes11')}</Typography>
                <Typography className="des">{t('termsConditions.noDes12')}</Typography>
                <Typography className="des">{t('termsConditions.noDes13')}</Typography>
                <Typography className="des">{t('termsConditions.noDes14')}</Typography>
                <Typography className="des">{t('termsConditions.noDes15')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no6')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes16')}</Typography>
                <Typography className="des">{t('termsConditions.noDes17')}</Typography>
                <Typography className="des">{t('termsConditions.noDes18')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no7')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes19')}</Typography>
                <Typography className="des">{t('termsConditions.noDes20')}</Typography>
                <Typography className="des">{t('termsConditions.noDes21')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no8')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes22')}</Typography>
                <Typography className="des">{t('termsConditions.noDes23')}</Typography>
                <Typography className="des">{t('termsConditions.noDes24')}</Typography>
                <Typography className="des">{t('termsConditions.noDes25')}</Typography>
                <Typography className="des">{t('termsConditions.noDes26')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no9')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes27')}</Typography>
                <Typography className="des">{t('termsConditions.noDes28')}</Typography>
                <Typography className="des">{t('termsConditions.noDes29')}</Typography>
                <Typography className="des">{t('termsConditions.noDes30')}</Typography>
                <Typography className="des">{t('termsConditions.noDes31')}</Typography>
                <Typography className="des">{t('termsConditions.noDes32')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no10')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes33')}</Typography>
                <Typography className="des">{t('termsConditions.noDes34')}</Typography>
                <Typography className="des">{t('termsConditions.noDes35')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no11')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes36')}</Typography>
                <Typography className="des">{t('termsConditions.noDes37')}</Typography>
                <Typography className="des">{t('termsConditions.noDes38')}</Typography>
                <Typography className="des">{t('termsConditions.noDes39')}</Typography>
                <Typography className="des">{t('termsConditions.noDes40')}</Typography>
                <Typography className="des">{t('termsConditions.noDes41')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no12')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes42')}</Typography>
                <Typography className="des">{t('termsConditions.noDes43')}</Typography>
                <Typography className="des">{t('termsConditions.noDes44')}</Typography>
                <Typography className="des">{t('termsConditions.noDes45')}</Typography>
                <Typography className="des">{t('termsConditions.noDes46')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no13')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes47')}</Typography>
                <Typography className="des">{t('termsConditions.noDes48')}</Typography>
                <Typography className="des">{t('termsConditions.noDes49')}</Typography>
                <Typography className="des">{t('termsConditions.noDes50')}</Typography>
                <Typography className="des">{t('termsConditions.noDes51')}</Typography>
                <Typography className="des">{t('termsConditions.noDes52')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no14')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes53')}</Typography>
                <Typography className="des">{t('termsConditions.noDes54')}</Typography>
                <Typography className="des">{t('termsConditions.noDes55')}</Typography>
                <Typography className="des">{t('termsConditions.noDes56')}</Typography>
                <Typography className="des">{t('termsConditions.noDes57')}</Typography>
                <Typography className="des">{t('termsConditions.noDes58')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no15')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes59')}</Typography>
                <Typography className="des">{t('termsConditions.noDes60')}</Typography>
                <Typography className="des">{t('termsConditions.noDes61')}</Typography>
                <Typography className="des">{t('termsConditions.noDes62')}</Typography>
                <Typography className="des">{t('termsConditions.noDes63')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no16')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes64')}</Typography>
                <Typography className="des">{t('termsConditions.noDes65')}</Typography>
                <Typography className="des">{t('termsConditions.noDes66')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no17')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes67')}</Typography>
                <Typography className="des">{t('termsConditions.noDes68')}</Typography>
                <Typography className="des">{t('termsConditions.noDes69')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{t('termsConditions.no18')}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('termsConditions.noDes70')}</Typography>
                <Typography className="des">{t('termsConditions.noDes71')}</Typography>
              </Stack>
            </InfoItem>
          </Stack>
        </Container>
      </TermsConditionsBox>
      <Footer />
    </TermsConditionsContentBox>
  );
};

export default TermsConditionsContent;
