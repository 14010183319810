import { styled, Box, Typography, Stack, Container, Avatar, Button } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import logo from 'assets/header/logo.png';
import discord from 'assets/footer/discord.png';
import twitter from 'assets/footer/twitter.png';
import tg from 'assets/footer/tg.png';
import medium from 'assets/footer/medium.png';
import android from 'assets/home/android.png';
import appStore from 'assets/home/appStore.png';
import chrome from 'assets/home/chrome.png';
import googlePlay from 'assets/home/googlePlay.png';
import { useNavigate } from 'react-router-dom';
import mobile5 from 'assets/home/mobile5.png';
import ellipseBg from 'assets/footer/ellipseBg.png';
import ellipseH5Bg from 'assets/footer/ellipseH5Bg.png';

const FooterBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(126),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: theme.spacing(135),
  },
}));

const FooterTop = styled(Stack)(({ theme }) => ({
  background: '#0096C7',
  backgroundImage: `url(${mobile5}), url(${ellipseBg})`,
  backgroundPosition: `calc(100% - 100px) bottom, calc(100%) bottom`,
  backgroundSize: `530.7px 474.5px, 910px 910px`,
  backgroundRepeat: 'no-repeat, no-repeat',
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: theme.spacing(62.25),
  paddingTop: theme.spacing(12.5),
  '& .getStarted': {
    fontSize: 40,
    fontWeight: 700,
  },
  '& .title': {
    color: '#fff',
    fontSize: 20,
    fontWeight: 700,
  },
  '& .describe': {
    color: '#A5ADCF',
    fontSize: 16,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(3),
    height: theme.spacing(54),
    paddingTop: theme.spacing(6),
    backgroundImage: `url(${mobile5}), url(${ellipseH5Bg})`,
    backgroundPosition: `calc(50% + 14px) bottom, right top`,
    backgroundSize: `234.63px 210.42px, 380px 440px`,
    '& .getStarted': {
      fontSize: 24,
      textAlign: 'center',
    },
    '& .title': {
      fontSize: 16,
    },
    '& .describe': {
      fontSize: 14,
      textAlign: 'center',
    },
  },
}));

const DownloadBox = styled(Stack)(({ theme }) => ({
  width: theme.spacing(60),
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    width: '100%',
  },
}));

const DownloadBtn = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  height: theme.spacing(7),
  width: theme.spacing(27),
  padding: theme.spacing(0, 0, 0, 1.5),
  borderRadius: theme.spacing(1.5),
  textTransform: 'unset',
  border: 'none',
  background: '#fff',
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  '&:hover': {
    border: 'none',
    background: '#fff',
  },
  '& .appStore': {
    width: '30px',
    height: '37px',
  },
  '& .googlePlay': {
    width: '33px',
    height: '37px',
  },
  '& .chrome': {
    width: '43.45px',
    height: '36.8px',
  },
  '& .android': {
    width: '30.91px',
    height: '39px',
  },
  '& .where': {
    fontSize: 12,
    fontWeight: 400,
  },
  '& .store': {
    fontSize: 15,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    width: `calc(50% - ${theme.spacing(0.5)})`,
    boxShadow: '0px 2.7672955989837646px 13.836478233337402px 0px rgba(193, 193, 193, 0.25)',
    '& .appStore': {
      width: '20.8px',
      height: '25.6px',
    },
    '& .googlePlay': {
      width: '22.9px',
      height: '25.6px',
    },
    '& .chrome': {
      width: '30.1px',
      height: '25.5px',
    },
    '& .android': {
      width: '21.4px',
      height: '27px',
    },
    '& .where,.store': {
      zoom: 0.8,
    },
  },
}));

const FooterLeft = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
  width: theme.spacing(23),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gap: theme.spacing(3),
    alignItems: 'flex-start',
  },
}));

const LogoBox = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: theme.spacing(4),
  gap: theme.spacing(1),
}));

const Logo = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4.5),
  width: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

const ContactBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0),
  },
}));

const ContractDes = styled(Typography)(({ theme }) => ({
  color: '#9C9CAB',
  fontSize: 14,
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const SocialContact = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: theme.spacing(4),
  alignItems: 'center',
}));

const SocialContactIcon = styled('img')(({ theme }) => ({
  height: theme.spacing(2.5),
  width: theme.spacing(2.5),
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

const QuickLinkList = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4.5),
  },
}));

const QuickLinkItem = styled(Stack)(({ theme }) => ({
  flex: 1,
  gap: theme.spacing(2),
  '& .title': {
    fontSize: 16,
  },
  '& .link': {
    fontSize: 14,
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: `calc(50% - ${theme.spacing(3)})`,
  },
}));

const FooterBottom = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  background: '#fff',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const BottomContainer = styled(Stack)(({ theme }) => ({
  height: theme.spacing(40),
  gap: theme.spacing(10),
  borderRadius: theme.spacing(3),
  background: '#fff',
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  padding: theme.spacing(0, 4),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(8, 0),
    gap: theme.spacing(3),
    boxShadow: 'unset',
  },
}));

const FooterRight = styled(Stack)(({ theme }) => ({
  flex: 1,
}));

const Footer = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const contactIconList = [
    { icon: twitter, alt: 'twitter', link: 'https://twitter.com/lynk_social' },
    { icon: tg, alt: 'tg', link: 'https://t.me/lynk1231' },
    { icon: medium, alt: 'medium', link: 'https://medium.com/@social_96859' },
    { icon: discord, alt: 'discord', link: 'https://discord.gg/4A7mfqs7He' },
  ];

  const composeEmail = () => {
    const recipient = 'bussiness@lynk.social';
    const subject = encodeURIComponent('');
    const body = encodeURIComponent('');
    const url = 'https://mail.google.com/mail/?view=cm&to=' + recipient + '&su=' + subject + '&body=' + body;
    return url;
  };

  const quickLinksList = [
    {
      title: t('footer.features'),
      link: '',
      children: [
        { name: t('footer.buyAssets'), link: '', path: '' },
        { name: t('footer.mobileWallet'), link: '', path: '' },
        { name: t('footer.hardwareWallet'), link: '', path: '' },
        { name: t('footer.pluginWallet'), link: '', path: '' },
      ],
    },
    {
      title: t('footer.developer'),
      link: '',
      children: [
        { name: t('footer.github'), link: '', path: '' },
        { name: t('footer.devDocs'), link: '', path: '' },
        { name: t('footer.submitApplication'), link: '', path: '' },
        { name: t('footer.pluginWallet'), link: '', path: '' },
      ],
    },
    {
      title: t('footer.company'),
      link: '',
      children: [
        { name: t('footer.aboutUs'), link: '', path: '/about-us/roadmap' },
        { name: t('footer.careers'), link: '', path: '' },
        { name: t('footer.brandMaterials'), link: '', path: '' },
      ],
    },
    {
      title: t('footer.support'),
      link: '',
      children: [
        { name: t('footer.helpCenter'), link: '', path: '' },
        { name: t('footer.contactUs'), link: composeEmail(), path: '' },
        { name: t('footer.privacyPolicy'), link: '', path: '/about-us/privacy-policy' },
        { name: t('footer.serviceAgreement'), link: '', path: '/about-us/terms-conditions' },
      ],
    },
  ];

  const navigateToPage = (path: string) => {
    if (path) {
      navigate(path);
    }
  };

  const jumpContact = (link: string) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <FooterBox>
      <FooterTop>
        <Container>
          <Stack gap={3}>
            <Typography className="getStarted" color={'#fff'}>
              {t('footer.getStarted')}
            </Typography>
            <DownloadBox>
              <DownloadBtn onClick={() => navigate('/login')}>
                <Avatar alt="App Store" className="appStore" src={appStore} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download1')}</Typography>
                  <Typography className="store">{t('home.download2')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Google Play" className="googlePlay" src={googlePlay} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download3')}</Typography>
                  <Typography className="store">{t('home.download4')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Chrome" className="chrome" src={chrome} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download5')}</Typography>
                  <Typography className="store">{t('home.download6')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Android" className="android" src={android} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download7')}</Typography>
                  <Typography className="store">{t('home.download8')}</Typography>
                </Stack>
              </DownloadBtn>
            </DownloadBox>
          </Stack>
        </Container>
      </FooterTop>
      <FooterBottom>
        <Container>
          <BottomContainer>
            <FooterLeft>
              <LogoBox>
                <Logo src={logo} variant="square" />
                <Typography fontSize={27} fontWeight={700}>
                  LYNK
                </Typography>
              </LogoBox>
              <ContactBox>
                <SocialContact>
                  {contactIconList.map(({ icon, alt, link }) => (
                    <SocialContactIcon key={alt} src={icon} alt={alt} onClick={() => jumpContact(link)} />
                  ))}
                </SocialContact>
                <ContractDes>{'Copyright 2023 © LYNK'}</ContractDes>
              </ContactBox>
            </FooterLeft>
            <FooterRight>
              <QuickLinkList>
                {quickLinksList.map(({ title, children }) => (
                  <QuickLinkItem key={title}>
                    <Typography className="title">{title}</Typography>
                    <Stack gap={2}>
                      {children.map(({ name, link, path }: { name: string; link: string; path: string }) => (
                        <Typography
                          key={name}
                          variant="body2"
                          className="link"
                          onClick={() => (link ? jumpContact(link) : navigateToPage(path))}
                        >
                          {name}
                        </Typography>
                      ))}
                    </Stack>
                  </QuickLinkItem>
                ))}
              </QuickLinkList>
            </FooterRight>
          </BottomContainer>
        </Container>
      </FooterBottom>
    </FooterBox>
  );
};

export default Footer;
