import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, Navigate } from 'react-router-dom';
import BaseLayout from 'layouts';
import theme from 'theme';
import { langData } from 'locales';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import ErrorBoundary from 'components/ErrorBoundary';
import { ScopedCssBaseline, styled } from '@mui/material';
import Loading from 'components/Loading';
import Home from 'pages/home';
import AboutUs from 'pages/aboutUs';
import Technology from 'pages/technology';
import TokenList from 'pages/tokenList';
import PrivacyPolicy from 'pages/privacyPolicy';
import TermsConditions from 'pages/termsConditions';

const CssBaseline = styled(ScopedCssBaseline)(({ theme }) => ({
  backgroundColor: '#fff',
  fontFamily: 'Aeonik Regular',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100%)',
  },
}));

function App() {
  return (
    <ErrorBoundary>
      <Suspense>
        <I18nProvider i18n={createI18n(langData, { lang: 'enUS' })}>
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <Loading />
              <Routes>
                <Route path="/" element={<BaseLayout />}>
                  <Route index element={<Navigate to={'/home'} />} />
                  <Route path="home" element={<Home />} />
                  <Route path="features" element={<Home />} />
                  <Route path="ecosystem">
                    <Route index element={<Navigate to={'/ecosystem/token-list'} />} />
                    <Route path="token-list" element={<TokenList />} />
                  </Route>
                  <Route path="about-us">
                    <Route index element={<Navigate to={'/about-us/roadmap'} />} />
                    <Route path="roadmap" element={<AboutUs />} />
                    <Route path="technology" element={<Technology />} />
                    <Route path="faq" element={<Technology />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="terms-conditions" element={<TermsConditions />} />
                  </Route>
                  <Route path="*" element={<Navigate to={'/home'} />} />
                </Route>
              </Routes>
            </ThemeProvider>
          </CssBaseline>
        </I18nProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
