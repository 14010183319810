import { styled } from '@mui/material';
import Header from 'components/Header';
import TermsConditionsContent from 'components/TermsConditionsContent';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  overflowX: 'hidden',
  gridTemplateRows: `${theme.spacing(9)} 1fr`,
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'header'
    'termsConditions'
  `,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(8)} 1fr`,
  },
}));

const HeaderBox = styled('div')({
  gridArea: 'header',
  background: '#0096C7',
});

const TermsConditions = () => {
  return (
    <MainContainer>
      <Header />
      <HeaderBox />
      <TermsConditionsContent />
    </MainContainer>
  );
};

export default TermsConditions;
