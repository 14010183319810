import { styled, Typography, Stack, Avatar } from '@mui/material';
import lineArrow from 'assets/aboutUs/lineArrow.png';

const RoadmapItemBox = styled(Stack)<{ isright: string }>(({ theme, isright }) => ({
  height: theme.spacing(44),
  marginLeft: isright ? '-12px' : 0,
  marginRight: isright ? 0 : '-12px',
  flexDirection: isright ? 'row' : 'row-reverse',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '150px',
    marginLeft: 0,
    marginRight: 0,
  },
}));

const RoadmapItemInfo = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  '& .quarter': {
    color: '#0096C7',
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
  },
  '& .title': {
    color: '#0096C7',
    fontWeight: 700,
    fontSize: 24,
  },
  [theme.breakpoints.down('sm')]: {
    '& .quarter': {
      zoom: 0.9,
      fontSize: 12,
      textAlign: 'left',
    },
    '& .title': {
      fontSize: 12,
    },
    '& .des': {
      fontSize: 12,
      zoom: 0.9,
    },
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
}));

const PolkaDot = styled(Stack)(({ theme }) => ({
  height: theme.spacing(3),
  width: '28px',
  borderRadius: theme.spacing(3),
  background: '#0096C7',
  [theme.breakpoints.down('sm')]: {
    height: '14px',
    width: theme.spacing(2),
  },
}));

const LineArrow = styled(Avatar)(({ theme }) => ({
  height: '6px',
  width: '65px',
  [theme.breakpoints.down('sm')]: {
    height: '3px',
    width: '32px',
  },
}));

const RoadmapItem = ({
  right,
  quarter,
  title,
  des,
}: {
  right?: boolean;
  quarter: string;
  title: string;
  des: string;
}) => {
  return (
    <RoadmapItemBox isright={right ? 'isright' : ''}>
      <PolkaDot />
      <LineArrow src={lineArrow} sx={{ transform: right ? 'rotate(180deg)' : 'rotate(0deg)' }} variant="square" />
      <RoadmapItemInfo>
        <Typography className="quarter">{quarter}</Typography>
        <Typography className="title">{title}</Typography>
        <Typography className="des" variant="body2">
          {des}
        </Typography>
      </RoadmapItemInfo>
    </RoadmapItemBox>
  );
};

export default RoadmapItem;
