import { styled, Typography, Stack, Container } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import useBreakpoint from 'hooks/useBreakpoint';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicyContentBox = styled('div')(({ theme }) => ({
  gridArea: 'privacyPolicy',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: '#fff',
}));

const PrivacyPolicyBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(9.5, 0),
  '& .pageDes': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4.5, 0),
    '& .pageDes': {
      fontSize: 16,
    },
  },
}));

const InfoItem = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2.5),
  '& .title': {
    fontSize: 40,
    fontWeight: 500,
  },
  '& .des': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  '& li': {
    color: '#000',
    fontSize: 18,
    fontWeight: 400,
  },
  [theme.breakpoints.down('sm')]: {
    '& .title': {
      fontSize: 24,
    },
    '& .des': {
      fontSize: 16,
    },
    '& li': {
      fontSize: 16,
    },
  },
}));

const PrivacyPolicyContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const isMobile = useBreakpoint();

  return (
    <PrivacyPolicyContentBox>
      <BaseHeader
        pageDes={''}
        title={t('privacyPolicy.title')}
        titleDes={`${t('privacyPolicy.lastUpdate')} 09/22/2023`}
        className="privacyPolicy"
      />
      <PrivacyPolicyBox>
        <Container>
          <Stack gap={isMobile ? 4.5 : 9.5}>
            <Typography className="pageDes">{t('privacyPolicy.pageDes')}</Typography>
            <InfoItem>
              <Typography className="title">{`1. ${t('privacyPolicy.no1')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes1')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes2')}</Typography>
                <ol>
                  <li>{t('privacyPolicy.sections1')}</li>
                  <li>{t('privacyPolicy.sections2')}</li>
                  <li>{t('privacyPolicy.sections3')}</li>
                  <li>{t('privacyPolicy.sections4')}</li>
                  <li>{t('privacyPolicy.sections5')}</li>
                  <li>{t('privacyPolicy.sections6')}</li>
                  <li>{t('privacyPolicy.sections7')}</li>
                  <li>{t('privacyPolicy.sections8')}</li>
                </ol>
                <Typography className="des">{t('privacyPolicy.noDes3')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes4')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes5')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`2. ${t('privacyPolicy.no2')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">
                  {t('privacyPolicy.personalData')}
                  <br />
                  {t('privacyPolicy.noDes6')}
                </Typography>
                <Typography className="des">{t('privacyPolicy.noDes7')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes8')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes9')}</Typography>
                <ul>
                  <li>{t('privacyPolicy.examples1')}</li>
                  <li>{t('privacyPolicy.examples2')}</li>
                  <li>{t('privacyPolicy.examples3')}</li>
                  <li>{t('privacyPolicy.examples4')}</li>
                  <li>{t('privacyPolicy.examples5')}</li>
                  <li>{t('privacyPolicy.examples6')}</li>
                  <li>{t('privacyPolicy.examples7')}</li>
                  <li>{t('privacyPolicy.examples8')}</li>
                </ul>
                <Typography className="des">{t('privacyPolicy.noDes10')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes11')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes12')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes13')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`3. ${t('privacyPolicy.no3')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes14')}</Typography>
                <ul>
                  <li>{t('privacyPolicy.include1')}</li>
                  <li>{t('privacyPolicy.include2')}</li>
                  <li>{t('privacyPolicy.include3')}</li>
                  <li>{t('privacyPolicy.include4')}</li>
                </ul>
                <Typography className="des">{t('privacyPolicy.noDes15')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes16')}</Typography>
                <ul>
                  <li>{t('privacyPolicy.ways1')}</li>
                  <li>{t('privacyPolicy.ways2')}</li>
                  <li>{t('privacyPolicy.ways3')}</li>
                  <li>{t('privacyPolicy.ways4')}</li>
                </ul>
                <Typography className="des">{t('privacyPolicy.noDes17')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes18')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes19')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`4. ${t('privacyPolicy.no4')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes20')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes21')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes22')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes23')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`5. ${t('privacyPolicy.no5')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes20')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes21')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes22')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes23')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`6. ${t('privacyPolicy.no6')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes24')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes25')}</Typography>
                <ul>
                  <li>{t('privacyPolicy.transfer1')}</li>
                  <li>{t('privacyPolicy.transfer2')}</li>
                </ul>
                <Typography className="des">{t('privacyPolicy.noDes26')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`7. ${t('privacyPolicy.no7')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes27')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes28')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes29')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes30')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes31')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes32')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes33')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes34')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes35')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes36')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes37')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes38')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes39')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes40')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes41')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes42')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes43')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes44')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes45')}</Typography>
                <Typography className="des">{t('privacyPolicy.noDes46')}</Typography>
              </Stack>
            </InfoItem>
            <InfoItem>
              <Typography className="title">{`8. ${t('privacyPolicy.no8')}`}</Typography>
              <Stack gap={2} width={'100%'}>
                <Typography className="des">{t('privacyPolicy.noDes47')}</Typography>
              </Stack>
            </InfoItem>
          </Stack>
        </Container>
      </PrivacyPolicyBox>
      <Footer />
    </PrivacyPolicyContentBox>
  );
};

export default PrivacyPolicyContent;
