import { styled } from '@mui/material';
import Header from 'components/Header';
import HomeContent from 'components/HomeContent';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  overflowX: 'hidden',
  gridTemplateRows: `${theme.spacing(9)} 1fr`,
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'header'
    'home'
  `,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(8)} 1fr`,
  },
}));

const HeaderBox = styled('div')({
  gridArea: 'header',
});

const Home = () => {
  return (
    <MainContainer>
      <Header isHome={true} />
      <HeaderBox />
      <HomeContent />
    </MainContainer>
  );
};

export default Home;
