import { useState, useEffect } from 'react';
import { styled, Box, Typography, Stack, Paper, List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import Arrow from '@mui/icons-material/ArrowDropDownOutlined';
import ExpandMore from '@mui/icons-material/ArrowDropDownOutlined';
import ExpandLess from '@mui/icons-material/ArrowDropUpOutlined';
import { router, languages } from './config';
import { useNavigate, useLocation } from 'react-router-dom';
import BaseTooltip from 'components/BaseTooltip';
import useBreakpoint from 'hooks/useBreakpoint';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateLanguage } from 'store/features/applicationSlice';

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(7.5),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MenuItemBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
  position: 'relative',
  width: 'fit-content',
}));

const MenuItem = styled(Box)<{ ishome: string }>(({ theme, ishome }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(4),
  gap: theme.spacing(0.5),
  width: 'fit-content',
  background: 'transparent',
  borderRadius: '10px',
  '& .menu-icon': {
    color: ishome ? '#0096C7' : '#fff',
  },
  '& svg': {
    color: ishome ? 'unset' : '#fff',
  },
  '&:hover': {
    '& .menu-title': {
      color: ishome ? '#0096C7' : '#fff',
    },
    '& svg': {
      color: ishome ? '#0096C7' : '#fff',
    },
  },
  transition: theme.transitions.create(['background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

const MenuChildrenItemBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
}));

const MenuChildrenItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  minWidth: '100%',
  gap: theme.spacing(3),
  background: '#fff',
  borderRadius: theme.spacing(1),
  overflowY: 'hidden',
  transition: theme.transitions.create(['height'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

const MenuChildren = styled(Stack)<{ disabled: boolean }>(({ theme, disabled }) => ({
  flexDirection: 'row',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.4 : 1,
  alignItems: 'center',
  gap: theme.spacing(3.5),
  padding: theme.spacing(0, 3),
}));

const MenuTitle = styled(Typography)<{ checked?: boolean; ishome?: string }>(({ checked, ishome }) => ({
  color: ishome ? '#393939' : '#fff',
  fontSize: 16,
  fontWeight: 700,
  whiteSpace: 'nowrap',
}));

const ItemText = styled(ListItemText)<{ path: string; checkpath: string }>(({ path, checkpath, theme }) => ({
  '& > span': {
    paddingRight: 20,
    margin: 0,
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 400,
    color: path === checkpath ? `${theme.textColor.text3}` : `${theme.textColor.text1}`,
    // background:
    //   path === checkpath
    //     ? `linear-gradient(75deg, ${theme.bgColor.bg3} 0, ${theme.bgColor.bg3} 92%, transparent 50%)`
    //     : 'transparent',
  },
}));

const Menu = ({ isWeb, isHome }: { isWeb?: boolean; isHome?: boolean }) => {
  const { t } = useI18n();
  const isMobile = useBreakpoint();
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.application);
  const [checkPath, setpCheckPath] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsePath, setpCollapsePath] = useState('');

  useEffect(() => {
    setpCheckPath(pathname);
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const selectPath = (path: string, children: Array<{ title: string; path: string }>) => {
    if (path === 'language') {
      setpCollapsePath(collapsePath === 'language' ? '' : 'language');
      return;
    }
    if (children.length) {
      setpCollapsePath(path === collapsePath ? '' : path);
    } else {
      if (!path.includes(collapsePath)) {
        setpCollapsePath('');
      }
      navigate({ pathname: path });
    }
  };

  const changeLang = (lang: string) => {
    dispatch(updateLanguage(lang));
  };

  return isMobile && !isWeb ? (
    <List
      component="nav"
      sx={{
        width: '100%',
        height: 'auto',
        overflowY: 'auto',
        background: '#fff',
      }}
      aria-labelledby="nested-list-subheader"
    >
      {router.map(({ title, path, children }) => {
        return (
          <Box
            key={title}
            sx={{
              bgcolor: path === collapsePath ? '#F2F9FF' : 'bgColor.bg1',
            }}
          >
            <ListItemButton sx={{ pl: 4 }} onClick={() => selectPath(path, children)} disableRipple>
              <ListItemText
                primary={t(title)}
                sx={{
                  flex: 'unset',
                  '& .MuiTypography-root': {
                    fontSize: 18,
                    fontWeight: 700,
                  },
                  color: path === collapsePath || checkPath.includes(path) ? 'text.text3' : 'background.paper',
                }}
              />
              {!children?.length ? null : path === collapsePath ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {children.length ? (
              <Collapse in={path === collapsePath} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  sx={{
                    width: '100%',
                  }}
                >
                  {children.map((el) => (
                    <ListItemButton
                      disableRipple
                      key={el.title}
                      sx={{ pl: 6, pt: 0, pb: 0 }}
                      onClick={() => selectPath(el.path, [])}
                    >
                      <ItemText checkpath={checkPath} path={el.path}>
                        {t(el.title)}
                      </ItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </Box>
        );
      })}
      <Box sx={{ bgcolor: collapsePath === 'language' ? '#F2F9FF' : 'bgColor.bg1' }}>
        <ListItemButton sx={{ pl: 4 }} onClick={() => selectPath('language', [])} disableRipple>
          <ListItemText
            primary={t('global.language')}
            sx={{
              flex: 'unset',
              '& .MuiTypography-root': {
                fontSize: 18,
                fontWeight: 700,
              },
              color: collapsePath === 'language' ? 'text.text3' : 'background.paper',
            }}
          />
          {collapsePath === 'language' ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={collapsePath === 'language'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ width: '100%' }}>
            {languages.map((el) => (
              <ListItemButton
                disableRipple
                key={el.title}
                sx={{ pl: 6, pt: 0, pb: 0 }}
                onClick={() => changeLang(el.lang)}
              >
                <ItemText checkpath={lang} path={el.lang}>
                  {t(el.title)}
                </ItemText>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </Box>
    </List>
  ) : (
    <MenuBox>
      {router.map((item) => (
        <MenuItemBox key={item.path}>
          {item.children.length ? (
            <BaseTooltip
              title={
                <MenuChildrenItemBox elevation={3}>
                  <MenuChildrenItem className="children-item-box">
                    {item.children.map(({ path, title, link, disabled }) => (
                      <MenuChildren
                        key={path}
                        disabled={disabled}
                        onClick={() => {
                          if (link) {
                            openLink(link);
                          } else {
                            checkPath !== path && !disabled && path && navigate(path);
                          }
                        }}
                      >
                        <MenuTitle ishome={'isHome'}>{t(title)}</MenuTitle>
                      </MenuChildren>
                    ))}
                  </MenuChildrenItem>
                </MenuChildrenItemBox>
              }
            >
              <MenuItem
                ishome={isHome ? 'isHome' : ''}
                className="menu-item"
                onClick={() => checkPath !== item.path && navigate(item.path)}
              >
                <MenuTitle
                  ishome={isHome ? 'isHome' : ''}
                  className="menu-title"
                  checked={checkPath.includes(item.parentPath)}
                >
                  {t(item.title)}
                </MenuTitle>
                {item.children.length ? (
                  <Arrow className={checkPath.includes(item.parentPath) ? 'menu-icon' : ''} />
                ) : null}
              </MenuItem>
            </BaseTooltip>
          ) : (
            <MenuItem
              ishome={isHome ? 'isHome' : ''}
              className="menu-item"
              onClick={() => checkPath !== item.path && navigate(item.path)}
            >
              <MenuTitle
                ishome={isHome ? 'isHome' : ''}
                className="menu-title"
                checked={checkPath.includes(item.parentPath)}
              >
                {t(item.title)}
              </MenuTitle>
              {item.children.length ? (
                <Arrow className={checkPath.includes(item.parentPath) ? 'menu-icon' : ''} />
              ) : null}
            </MenuItem>
          )}
        </MenuItemBox>
      ))}
    </MenuBox>
  );
};

export default Menu;
