import { styled, Typography, Container, Stack } from '@mui/material';
import starlink from 'assets/aboutUs/starlink.png';
import lock from 'assets/technology/lock.png';
import wave from 'assets/technology/wave.png';

const HeaderBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(43),
  padding: theme.spacing(8, 0, 10),
  backgroundImage: `url(${starlink}), linear-gradient(180deg, #0096C7 0%, #2761B8 100%)`,
  backgroundPosition: `80px bottom, left top`,
  backgroundSize: `765px 288px, 100% 100%`,
  backgroundRepeat: 'no-repeat, no-repeat',
  width: '100%',
  '&.technology': {
    backgroundImage: `url(${lock}), url(${wave}), linear-gradient(180deg, #0096C7 0%, #274CB8 100%)`,
    backgroundPosition: `calc(100% - 160px) center, 80px bottom, left top`,
    backgroundSize: `366px 252px, 100% 249px, 100% 100%`,
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    height: theme.spacing(29),
    backgroundImage: `url(${starlink}), linear-gradient(180deg, #0096C7 0%, #0096C7 100%)`,
    backgroundPosition: `-130px bottom, left top`,
    backgroundSize: `428px 183px, 100% 100%`,
    '&.technology': {
      backgroundImage: `url(${starlink}), linear-gradient(180deg, #0096C7 0%, #0096C7 100%)`,
      backgroundPosition: `-130px bottom, left top`,
      backgroundSize: `428px 183px, 100% 100%`,
    },
  },
}));

const HeaderHeader = styled(Stack)(() => ({
  height: '100%',
  width: '100%',
  alignItems: 'center',
}));

const HeaderHeaderInfo = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  height: '100%',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: 64,
  fontWeight: 500,
  lineHeight: '70px',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 'normal',
  },
}));

const HeaderTitleDes = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(5),
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: 16,
    marginBottom: theme.spacing(4),
  },
}));

interface BaseModalProps {
  pageDes: string;
  title: string;
  titleDes: string;
  className: string;
}

export default function BaseHeader(props: BaseModalProps): JSX.Element {
  const { pageDes, title, titleDes, className } = props;

  return (
    <HeaderBox className={className}>
      <Container>
        <HeaderHeader>
          <HeaderHeaderInfo>
            <Typography fontSize={14} fontWeight={700} color={'#fff'}>
              {pageDes}
            </Typography>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderTitleDes variant="body2">{titleDes}</HeaderTitleDes>
          </HeaderHeaderInfo>
        </HeaderHeader>
      </Container>
    </HeaderBox>
  );
}
