import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import applicationReducer from './features/applicationSlice';
import globalSliceReducer from './features/globalSlice';

const PERSISTED_KEYS: string[] = ['application'];

const store = configureStore({
  reducer: {
    application: applicationReducer,
    global: globalSliceReducer,
  },
  middleware: [save({ states: PERSISTED_KEYS, disableWarnings: true })],
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
