import React from 'react';
import { CircularProgress, Box, styled } from '@mui/material';
import { useAppSelector } from 'hooks/redux';

const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  position: 'absolute',
  backgroundColor: '#00000080',
  [theme.breakpoints.down('sm')]: {
    height: 'var(--doc-height)',
  },
}));

const Loading: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.global);

  return isLoading ? (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  ) : null;
};

export default Loading;
