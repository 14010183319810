export default {
  global: {
    login: 'Login',
    submit: 'Submit',
    manage: 'Manage',
    confirm: 'Confirm',
    enable: 'Enable',
    resend: 'Resend',
    language: 'Language',
  },
  navMenu: {
    ecosystem: 'Ecosystem',
    features: 'Features',
    aboutUs: 'About Us',
    tokenList: 'Token List',
    supportedChains: 'Supported Chains',
    roadmap: 'Roadmap',
    whyUs: 'Why Us',
    privacyPolicy: 'Privacy Policy',
    termsConditions: 'Terms & Conditions',
    faq: 'FAQ',
  },
  home: {
    pageDes: 'Join our secure MPC wallet!',
    title: 'The New Standard for Digital Asset Security',
    titleDes: `A trusted MPC wallet for digital asset management`,
    download1: 'Download on the',
    download2: 'App Store',
    download3: 'GET IT ON',
    download4: 'Google Play',
    download5: 'Available in the',
    download6: 'Chrome Web Store',
    download7: 'Download APK',
    download8: 'Android',
    networks: 'Support on over 60+ networks',
    exploreAll: 'Explore All',
    mpcSecurityTitle: 'MPC: Unbreakable Security',
    mpcSecurityDes: `Revolutionary Web3 wallet with MPC tech, keyless & non-custodial, compatible with multiple blockchains. Enhanced security with 3FA and encrypted user data.`,
    learnMore: 'Learn more',
    mpcSecurityList1: 'Advanced Security & Privacy',
    mpcSecurityList2: 'MPC technology, no private keys, encrypted data—ensuring utmost security and privacy.',
    mpcSecurityList3: 'User Control & Non-Custodial',
    mpcSecurityList4: 'Full control of funds, non-custodial operation—minimizing third-party involvement.',
    mpcSecurityList5: 'Compatibility & Ease of Use',
    mpcSecurityList6: `Compatible with multiple blockchains, user-friendly interface—accessible for all, quick and seamless setup.`,
    deFiIntegrations: 'DeFi Integrations',
    deFiIntegrationsDes: `Access and explore decentralized apps directly from your wallet, seamlessly transitioning between your favourite dApps.`,
    crossChain: 'Cross-Chain Trading',
    crossChainDes: `Access and explore decentralized apps directly from your wallet, seamlessly transitioning between your favourite dApps.`,
    viewTokens: 'View Supported Tokens',
    nftManagement: 'NFT Management',
    nftManagementDes: 'Track, showcase, and trade your digital assets .',
    userReviews: 'User Reviews',
    userReviewsNum: 'Trusted by more than 100K+ users',
    userReviewsDes: 'Hear what they say about us and why you should choose LYNK MPC Wallet',
    user1: `It is trustworthy and not only ensures the security of users'digital assets but also provides convenient digital asset management services, It is an indispensable choice for digital asset holders.`,
    user2: `LYNK's interface design is simple and beautiful, very easy to use. Both digital asset beginners and advanced users can easily get started with it. `,
    user3: `LYNK is a very convenient and easy-to-use MPC wallet with strong security and reliability, enabling users to securely store and manage their digital assets.`,
  },
  aboutUs: {
    pageDes: 'ABOUT US',
    title: 'About LYNK',
    titleDes: 'A more efficient, secure, and easy-to-use asset management wallet',
    productRoadmap: 'Product Roadmap',
    roadmapTitle: 'LYNK Roadmap',
    roadmap01: '2023.Q2',
    roadmapTitle01: 'Project Initiation',
    roadmapDes01: `LYNK was established after the completion of a feasibility analysis, along with the design of its underlying technical solution.`,
    roadmap02: '2023.Q3',
    roadmapTitle02: 'MPC Solution Development',
    roadmapDes02: `MPC fragmentation and social recovery solutions were implemented, together with the development of product contracts, mobile app and the official website.`,
    roadmap03: '2023.Q4',
    roadmapTitle03: 'Alpha Launch',
    roadmapDes03: `Alpha launch of MPC wallet, with full platform support for iOS/Android. Further support for multiple chains and currencies, release of wallet as a service (WaaS) SDK/API, enriched token management, and functionality for NFT management.`,
    roadmap04: '2024.Q1',
    roadmapTitle04: 'Cross-Chain Aggregation',
    roadmapDes04: `Support for smart contract account ERC4337, together with support for cross-chain aggregation on multiple chains.`,
    roadmap05: '2024.Q2',
    roadmapTitle05: 'Further Integrations & AI Interactions',
    roadmapDes05: `Support for leading chains and dApp integrations. Further functionality features with the inclusion of AI interactions, and practical tools for airdrop programmes.`,
    whyUs: 'Why us',
    whyUsTitle: 'Replace Traditional Wallets',
    whyUsDes: 'Comparison between MPC-based AA wallet and traditional wallet',
    tab1: 'Features',
    tab2: 'Smart Contract Wallet',
    tab3: 'Hardware Wallet',
    tableTitle1: 'Support for multi-signature',
    tableTitle2: 'Support for permission allocation',
    tableTitle3: 'Personal_Sign',
    tableTitle4: 'Eth_sign Typed Date (V1-V4)',
    tableTitle5: 'Is it an EOA account?',
    tableTitle6: 'On-chain gas fee',
    tableTitle7: 'Support for Web3 applications',
    tableTitle8: 'Network Support',
    tableTitle9: ' API automation workflow',
    tableTitle10: 'Fees',
    noGas: 'No Gas Required',
    normal: 'Normal',
    allNetworks: 'All Networks',
    free: 'Free',
    gasPayment: 'Gas payment required',
    high: 'High',
    partial: 'Partial',
    evmNetworks: 'Some EVM Networks',
    all: 'All',
    moderate: 'Moderate',
    faq: 'FAQ',
    askedQuestionsTitle: 'Frequently Asked Questions',
    askedQuestions1: 'What is the MPC wallet? How is it different from the original MPC wallet?',
    askedQuestionsDes1: `The MPC wallet is a digital wallet that uses multiparty computation (MPC) technology. Compared with traditional wallets, it is more secure. MPC technology can divide some private data and calculate it separately by different participants without exposing the original data, thereby ensuring data privacy. In contrast, traditional wallets typically save private keys using mnemonic phrases or private keys. Once these private keys are leaked, users digital assets will be threatened.`,
    askedQuestions2: 'What are the characteristics of this platform?',
    askedQuestionsDes2: `Support for multiple public chains, easy cross-chain transactions, multiple tool services, secure access reminder for dApps, and secure storage of private keys.`,
    askedQuestions3: ' What is the most important feature of the MPC wallet?',
    askedQuestionsDes3: `The core function of an MPC wallet is multi-party computation (MPC) technology, which allows data to be shared among multiple participants while maintaining data security and privacy. In an MPC wallet, this technology is applied to private key management, allowing users to perform transactions and asset management without revealing their private keys. The application of this technology is the core function of an MPC wallet and the biggest difference between it and other wallet products.`,
    askedQuestions4: 'What functions will be available in the first version?',
    askedQuestionsDes4: `MPC multi-party computation function, support for multiple chains, support for cross-chain exchange on multiple chains, decentralization, non-custodial, and chain-based social recovery`,
    askedQuestions5: 'Will using the wallet make it easier to earn profits, such as airdrops or trading profits?',
    askedQuestionsDes5: `Due to the security of the MPC wallet, it is more reassuring to interact on the chain; In addition, 1Keeper will also launch some small programs and tools in the future to facilitate the interaction of airdrops, quickly obtain consultations on hot tokens and LYNKs.`,
    showMore: 'Show More',
  },
  solutionmal: {
    pageDes: 'LYNK MPC Solutionmal',
    title: 'Protected with MPC technology',
    titleDes: 'The most secure technology for digital asset management.',
    overview: 'Overview',
    overview1: `LYNK is an open-source, transparent, and decentralized digital asset management tool created by a team of experienced blockchain developers with practical experience in cryptography.`,
    overview2: `In the field of cryptocurrencies, it is crucial to securely store private keys and prevent fraudulent transactions. This is because crypto transactions, by definition, are irreversible, making stolen funds almost impossible to recover.`,
    overview3: `LYNK's solution is based on not having a single device holding the keys used for generating signatures and transferring funds. The keys are shared between two devices (servers/mobile phones/laptops ), ensuring that neither party has any information about the keys. To generate signatures, the two devices run a secure multi-party computation protocol that generates signatures without revealing any information about the shared keys to each other.`,
    overview4: `LYNK leverages institutional-grade multi-party computation to protect and manage private keys.`,
    mpcWorksInfo: 'Multi-party computation',
    mpcWorks: 'How MPC works?',
    mpcWorksDes1: `Multi-Party Computation (MPC) is a cutting-edge technology that revolutionizes the way data is processed and secured. At its core, MPC enables secure computation across multiple parties without revealing individual inputs or compromising sensitive information.`,
    mpcWorksDes2: `Through a collaborative approach, participants jointly compute on encrypted data, ensuring privacy and maintaining the confidentiality of individual inputs. By dividing private keys and distributing them among participants, MPC ensures that no single party has access to the complete private key, preventing unauthorized access and reducing the risk of single point of failure.`,
    mpcWorksDes3: `MPC technology also offers robust resistance against quantum computing attacks, as even if an attacker manages to obtain partial keys, they are unable to exploit this information to break the encryption algorithm.`,
    mpcWorksDes4: `LYNK incorporates advanced MPC technology to provide unparalleled security, ensuring that your digital assets are always custodied within your control.`,
    shredModelInfo: 'MPC Model',
    shredModel: 'Shred Model',
    shredModelDes: `LYNK employs a 2-of-3 shard model, where any two out of three participants can perform cryptographic operations. Shard A, the key share, is used by the user device and trustee service. Shard B is used by the user device and wallet service provider, while Shard C is shared between the wallet service provider and trustee service.`,
    mobileDevice: 'Mobile Device',
    serviceProvider: 'Wallet service Provider',
    trusteeService: 'Trustee service',
    blockchain: 'Blockchain',
    enhancedSecurity: 'Enhanced Security',
    enhancedSecurityDes: `MPC technology allows for enhanced security via the segregation of keys, preventing hacks before they happen.`,
    custodial: 'Non-Custodial',
    custodialDes: `Retain full control of your private keys and funds, reducing the risk of unauthorized access.`,
    privacyProtection: 'Privacy Protection',
    privacyProtectionDes: `Enhanced data privacy via MPC technology allows for the reduction of user data leaks.`,
    antiCensorship: 'Anti-Censorship',
    antiCensorshipDes: `Signing transactions independently without relying on centralized servers, ensuring censorship resistance.`,
    userFriendly: 'User-Friendly',
    userFriendlyDes: `Lightweight and intuitive design, making it easy to set up even for users with no blockchain experience.`,
    noDataStorage: 'No Data Storage',
    noDataStorageDes: `Granting full control to the users and protecting against potential data breaches or theft.`,
  },
  ecosystem: {
    title: 'Our Ecosystem',
    titleDes: 'Explore our ecosystem, with over 60+ networks & 9m+ tokens supported',
    supportedList: 'Supported List',
    supportedListDes: `LYNK Crypto Assets Supported List`,
    searchPlh: `Search name or paste address`,
    coins: 'Coins',
    chains: 'Chains',
    tokens: '9m+ Tokens Supported...',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    lastUpdate: 'Last update:',
    pageDes: `This is the privacy policy ("Privacy Policy") for any application or web service (collectively referred to as the "Platform") owned or operated by LYNK Limited ("us," "we," or "LYNK").`,
    no1: 'Introduction',
    noDes1: `This Privacy Policy explains who we are, why and how we process personal data collected through your use of the Platform, what rights you have as a data subject, and how to contact us if needed.`,
    noDes2: `When you provide us with any personal data, we have a legal obligation to use that data fairly. We must collect information fairly and explain how we will use that information. For ease of reading, we have divided this Privacy Policy into several sections:`,
    noDes3: `It is important that you read this Privacy Policy, as well as any other privacy notices or fair processing notices that we may provide before or after collecting or processing your personal data, to fully understand how and why we use that data. This Privacy Policy supplements other notices and legal documents, including the Terms of Service ("Terms"), and is not intended to override or replace them.`,
    noDes4: `If you do not agree with the terms of this Privacy Policy for any reason, please discontinue using the Platform. We reserve the right to revise or modify this Privacy Policy at any time to reflect changes in our business or legal requirements. If these changes are significant, we will make efforts to notify all registered users by email to ensure they are aware of such changes.`,
    noDes5: `Please note that the Platform is not intended for use by anyone under the age of 18 and is not directed at anyone. We do not knowingly collect data related to children. If you believe we have collected personal information about your child, you can contact us at bussiness@lynk.social and request that we delete their information.`,
    sections1: 'Introduction',
    sections2: 'Information we collect',
    sections3: 'How and why we use/share your information',
    sections4: 'How long we retain your information',
    sections5: 'Security',
    sections6: 'International data transfers',
    sections7: 'Your rights',
    sections8: 'Contact details',
    no2: 'Information we collect',
    personalData: 'What is personal data? ',
    noDes6: `When this Privacy Policy refers to "personal data," it means data about you that can identify you as an individual, such as your name, date of birth, or contact information. Under the law, all organizations that process your personal data in the UK and Europe have an obligation to handle your personal data in specific ways and ensure that you are provided with appropriate information about how they use that data. You also have various rights to seek information from these organizations about how they use your data and to prevent them from unlawfully processing that information. For more information on these rights, please refer to the "Your Rights" section of this Privacy Policy.`,
    noDes7: `How and What Types of Data We Collect When You Use the Platform When you register an account, fill out forms provided by the Platform, subscribe to our services (such as "LYNK Guard"), or report platform issues, we may collect, store, and use certain personal information disclosed to us.`,
    noDes8: `The information we may collect from you may include (but is not limited to): your IP address, email address, phone number, feedback, and login information.`,
    noDes9: `When you access the Platform and interact with it, we also collect information about you through technologies like cookies. Here are examples of the information we may collect:`,
    examples1: 'Information about your device, browser, or operating system.',
    examples2: 'Time zone settings.',
    examples3: 'Your IP address.',
    examples4: 'Information about your interaction with the Platform (e.g., scrolling and clicking).',
    examples5: 'Information about how you use the Platform and the actions you take within it.',
    examples6: 'Time spent on the Platform and time spent in specific sections.',
    examples7: 'Response times.',
    examples8: 'Download errors.',
    noDes10: `Please refer to the help materials of your device to learn what controls you can use to delete cookies, block cookies, or disable IDFA tracking. Doing so may impact your use of our website or mobile application.`,
    noDes11: `We may also work with third parties who may collect anonymous usage or statistical data about your use of the Platform (e.g., subcontractors for technical, payment, and/or delivery services, business partners, advertising networks, analytics providers).`,
    noDes12: `We may collect information about how you use the Platform through Google Play's Android Advertising ID technology and Apple's iOS Advertising Identifier. This information is used solely by us and trusted third-party service providers (as described below under "Service Providers") for advertising and user analytics purposes. The advertising identifier is associated with your personally identifiable information only with your explicit consent. We respect your choices regarding this technology and will not use the data in any way you request us not to. We commit to comply with Google and Apple's terms of use. If you have any questions about our use of this technology, you can directly contact us (via bussiness@lynk.social), Google, or Apple.`,
    noDes13: `Updating Your Information If you wish to update the information you have previously provided to us, you can contact us at bussiness@lynk.social.`,
    no3: 'How and why we use/share your information',
    noDes14: `Legal Basis for Processing Your Information We will only use your personal data where permitted by law. The most common scenarios in which we use your personal data include:`,
    include1: 'Where you have requested us to do so or consented to our use.',
    include2: 'Where it is necessary for the performance of a contract we have with you.',
    include3: `Where our (or a third party's) legitimate interests are necessary, and your fundamental rights do not override those interests.`,
    include4: 'Where we need to comply with legal or regulatory obligations.',
    noDes15: `Marketing If you have consented or if you have provided feedback on our platform and have not opted out of receiving marketing communications, you will receive our marketing communications (which is within our legitimate rights to provide marketing services to you as an individual freely and in balance with your rights and interests). To unsubscribe from marketing emails at any time, click the unsubscribe link at the bottom of any marketing email and update your account preferences. If you do not wish to receive any marketing materials from us, you can also contact us to notify us.`,
    noDes16: `Sharing Your Information Depending on how and why you provide personal data to us, we may share that data in the following ways:`,
    ways1: `We may share your personal information with any member of our group of companies where there is a legitimate lawful reason for doing so. This means our subsidiaries, our ultimate holding company, and its subsidiaries as defined in section 1159 of the UK Companies Act 2006.`,
    ways2: `With selected third parties, including business partners, suppliers, and subcontractors, to fulfill any contracts we have with them or you (referred to as "Service Providers").`,
    ways3: `Sharing information to help us improve and optimize the Platform through analytics. We may also disclose your personal information to third parties in the following circumstances:`,
    ways4: `If we are under a duty to disclose or share your personal information to comply with any legal obligation or to enforce or apply our terms; or to protect the rights, property, or safety of our company, our customers, or others. This includes exchanging information with other companies and organizations for fraud prevention and credit risk reduction.`,
    noDes17: `Service Providers Our service providers provide us with various administrative, statistical, and technical services. We only provide service providers with the minimum amount of personal data necessary for them to perform the services we require, and we require them to protect the information and not use it for any other purposes. We take these relationships seriously and require all our data processors to sign contracts with us that clearly set out their commitment to respecting individual rights and assisting us in helping you exercise your rights as a data subject. Our current third-party service providers include Amazon Web Services, Google Analytics, Amplitude, Branch, SendGrid, Substack, Customer.io, Zendesk, Twilio, Mezmo, Datadog, Snowflake, Moonpay, Ramp, Stripe, Coinbase, Wyre, Segment, and Firebase.`,
    noDes18: `Other Disclosures During your use of the Platform, your application store provider, mobile network operator, third-party APIs, or others may also collect personal information about your use of the Platform, such as your identity, usage, and location. These third parties act as separate and independent controllers of that personal data and should handle it according to their own privacy policies.`,
    noDes19: `Links to Third-Party Websites The Platform may occasionally contain links to websites of our partner networks, advertisers, and affiliates. We may also provide links to third-party websites unrelated to the Platform. Third-party websites are not under our control and are not covered by this Privacy Policy. If you access third-party websites through the provided links, the operators of those websites may collect information from you in accordance with their own privacy policies. Please review these policies before submitting any personal data to those websites.`,
    no4: 'How long we retain your information ',
    noDes20: `LYNK takes the protection of your information very seriously. We have implemented appropriate physical, electronic, and managerial security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed, including the use of secure servers, passwords, and industry-standard encryption for data transmission and at rest (we use the latest version of SSL for data in transit and AES-256 for static data).`,
    noDes21: `If we have provided you with a password that allows you to access certain parts of our platform, you are responsible for keeping that password confidential. We ask you not to share your password with anyone.`,
    noDes22: `Furthermore, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions and have a duty of confidentiality.`,
    noDes23: `We have procedures in place to deal with any suspected personal data breach, and we will notify you and any applicable regulatory authority of a breach where we are legally required to do so.`,
    no5: 'Security',
    no6: 'International data transfers',
    noDes24: `Please note that some of our service providers may be located outside the United Kingdom ("UK") and the European Economic Area ("EEA"). These service providers may work for us or one of our suppliers and may be involved in fulfilling your requests for information, products, and services, processing your payment details, and providing support services.`,
    noDes25: `If we transfer your data to service providers outside the UK and/or EEA, we will seek to ensure that appropriate safeguards are in place to secure your personal data and maintain your rights as a data subject. The transfer of personal data may be:`,
    transfer1: `To countries recognized by the European Commission and/or the UK Secretary of State as providing an adequate level of protection, or`,
    transfer2: `To countries/regions not providing adequate protection but where appropriate safeguards have been implemented, such as the European Commission's Standard Contractual Clauses, UK International Data Transfer Clauses, or by implementing other suitable cross-border transfer solutions.`,
    noDes26: `By submitting your personal information, you agree to this transfer, storage, or processing. If you would like more information about the mechanisms for the transfer of your personal data, please contact bussiness@lynk.social.`,
    no7: 'Your Rights',
    noDes27: `As a data subject, you have various rights regarding your personal data. Below, we describe the different rights you have and how to exercise them.`,
    noDes28: `Right of Access You can request access to the personal data we hold about you at any time (this right is often referred to as a "subject access request").`,
    noDes29: `Please note that this right allows you to obtain a copy of the personal data we hold about you so that you can check its accuracy and ensure that we are processing that personal data lawfully. This is not a right that allows you to request personal data about other individuals or specific files unrelated to your personal data.`,
    noDes30: `You can exercise this right by writing to us using the contact details listed here and informing us that you are making a subject access request. You do not need to fill out a specific form to make such a request.`,
    noDes31: `Your Rights to Rectification and Erasure You can request the correction of any personal data we hold about you that you believe is inaccurate or incomplete. Please note that we may ask you to verify any new data you provide to us and may take our own steps to check the accuracy of the new data you have provided.`,
    noDes32: `If you believe that we no longer need to retain personal data about you, you can also request the erasure of personal data we control (this right is often referred to as the "right to be forgotten"). While we will make every effort to respect your requests and personal data, it may not always be possible to delete all of your personal data due to legal requirements to retain certain personal data or technical limitations on the data we can delete; for example, we cannot fulfill requests to delete records and information stored on the blockchain, including but not limited to usernames you have chosen, your transaction history, and your account balances. Retaining certain data may also be in line with legitimate interests, including but not limited to data necessary for the operation of the platform. If this is the case, we will continue to process this data.`,
    noDes33: `If deletion is technically impossible or if we believe we have sufficient legal grounds to continue processing personal data you have requested us to delete, we will inform you of this and provide our reasoning in our response to your request.`,
    noDes34: `You can exercise this right by writing to us using the contact details listed here and informing us of your request for rectification or erasure of your personal data and the basis for your request. If you would like us to replace inaccurate data with new data, you should inform us of the new data. You do not need to fill out a specific form to make such a request.`,
    noDes35: `Your Right to Restrict Processing If we are processing your personal data based on legitimate interests, and if you believe that continuing to do so would impact your fundamental rights and freedoms, or if you believe those legitimate interests are affected, you have the right to request that we stop processing the data in this way.`,
    noDes36: `You can also request that we stop processing your personal data: (a) if you dispute the accuracy of the personal data and wish us to verify its accuracy; (b) if it has been determined that our use of the data is unlawful but you do not want us to delete it; (c) if we no longer need to process your personal data (and we will dispose of it in other ways) but you want us to continue storing it to enable you to establish, exercise, or defend legal claims.`,
    noDes37: `Please note that if, for any reason, we believe we have sufficient legal grounds to continue processing personal data you have requested us to stop processing, we will inform you of this and provide you with an opportunity to consider and investigate it.`,
    noDes38: `You can exercise this right at any time by writing to us using the contact details listed here, informing us of the relevant aspects of your request to stop processing your personal data, and specifying which of the above circumstances you believe apply to your request. You do not need to fill out a specific form to make such a request.`,
    noDes39: `Your Right to Data Portability If you wish to have certain personal data we hold about you (processed by automated means) transferred to a third party, you can write to us and request that we provide it to you in a commonly used machine-readable format.`,
    noDes40: `Given the nature of the work we do and the systems we use, we do not consider this right to be particularly relevant to the majority of individuals we interact with. However, if you would like your data to be transferred from us to a third party, we are happy to consider such requests.`,
    noDes41: `Your Right to Object to Processing If we rely on legitimate interests to process your personal data, you have the right to object to the processing of your personal data. We will comply with your request unless we have compelling legitimate grounds for processing that override your interests, rights, and freedoms, or if we need to continue processing your personal data for the establishment, exercise, or defense of legal claims.`,
    noDes42: `Your Right to Stop Receiving Communications For detailed information about your right to request us to stop sending you various communications, please contact us.`,
    noDes43: `Your Right to Object to Automated Decision Making and Profiling You have the right to be informed about the existence of any automated decision making and profiling of your personal data and, in certain cases, be provided with meaningful information about the logic involved, as well as the significance and expected consequences of such processing.`,
    noDes44: `Your Right to Withdraw Consent If we rely on consent to process your personal data, you can withdraw your consent at any time. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide you with certain products or services. If this is the case, we will notify you when you withdraw your consent.`,
    noDes45: `Exercising Your Rights When you write to us to request the exercise of your rights, we have the right to ask you to prove that you are the person you claim to be. We may ask you to provide copies of relevant identification documents to help us verify your identity.`,
    noDes46: `It will assist us in processing your request if you clearly state which right(s) you wish to exercise and provide reasons for exercising that right in the relevant circumstances. The more clear and specific you are, the faster and more efficiently we can process your request. If you do not provide us with sufficient information, we may delay processing your request until you provide us with additional information (if this is the case, we will inform you).`,
    no8: `Contact details`,
    noDes47: `If you have any questions about this privacy policy, wish to exercise any of the aforementioned rights, or believe that the privacy policy has not been complied with, please contact us by sending an email to bussiness@lynk.social.`,
  },
  termsConditions: {
    title: 'Terms & Conditions',
    lastUpdate: 'Last update:',
    no1: 'Welcome',
    noDes1: `a. LYNK Limited ("LYNK," "we," "our," or "us") develops a self-custodial MPC wallet that allows users to execute and manage cryptographic asset transactions on compatible blockchains and similar networks ("LYNK Wallet(s)").`,
    noDes2: `b. LYNK provides two different types of LYNK Wallets: (i) the LYNK application ("LYNK App"), which can be downloaded on your mobile device; each LYNK Wallet has independent cryptographic assets. The LYNK Wallets accessed through the LYNK App and browser extension are not interchangeable, meaning that cryptographic assets held through the LYNK App cannot be accessed through the browser extension and vice versa.`,
    noDes3: `c. These Terms of Service apply to the use of the LYNK App and the LYNK Wallets accessed through the LYNK App.`,
    no2: `Your Relationship with Us`,
    noDes4: `a. This document, along with any documents referenced herein (collectively referred to as the "Terms of Service"), sets forth the terms of your relationship with us. Please read and understand the Terms of Service before using the LYNK App.`,
    noDes5: `b. By using and accessing the LYNK App, you agree to these Terms of Service. If you do not agree to these Terms of Service, please do not use the LYNK App.`,
    noDes6: `c. The LYNK App is intended for non-commercial use only; you may only use the LYNK App for personal use.`,
    no3: `About Our Information`,
    noDes7: `a. LYNK is a company registered in the British Virgin Islands, with the registered address at Morgan Building, Pasea Estate, Road Town, Tortola.`,
    noDes8: `b. If you wish to contact us, please use the contact details provided at the end of these Terms of Service.`,
    no4: `About Your Information`,
    noDes9: `a. Your privacy is important to us. Please read our Privacy Policy to understand how we collect, use, and share information about you.`,
    no5: `Important Warning about LYNK Wallet`,
    noDes10: `A. Cryptocurrency Trading i. The LYNK application allows you to initiate and manage cryptocurrency transactions on the blockchain and compatible networks ("compatible networks") that are compatible with the LYNK Wallet. Trading or transacting with cryptocurrencies carries inherent risks as the prices of cryptocurrencies can be highly volatile. We cannot guarantee the value of any cryptocurrencies you purchase will not decrease. Therefore, the risk of buying or selling any cryptocurrencies through the LYNK app is solely your responsibility. ii. The completion of transactions initiated through the LYNK application depends on the availability and operation of the relevant compatible networks with which you interact using the LYNK application. Issues with compatible networks may result in transaction failures initiated through the LYNK app. This may mean that the transaction you intended to execute becomes unavailable. Due to the decentralized nature of these compatible networks, LYNK is not responsible for any errors or losses incurred as a result of such issues or transaction failures.`,
    noDes11: `B. Regulation i. The LYNK application is not intended for storing or transmitting legal tender and LYNK is not authorized by the Financial Conduct Authority in the UK or any other regulatory body to engage in regulated activities. The cryptocurrencies managed through the LYNK app are not protected or insured by any regulatory guarantee or compensation scheme, such as the Financial Services Compensation Scheme in the UK.`,
    noDes12: `C. Network Fees i. To execute transactions on certain compatible networks, you will need to pay network fees set by the operators of the relevant compatible networks, which may fluctuate based on network congestion ("network fees"). If the network fees for a transaction are set too low, the transaction may fail, and you may still be liable for a certain percentage of the network fees, even if your transaction fails. ii. We recommend network fees for your transactions based on current network demand. You acknowledge that unexpected spikes in network usage may result in network fees suddenly increasing above the price we recommended for your transaction. We are not responsible for any losses incurred due to transaction failures caused by setting the network fees too low.`,
    noDes13: `D. Beta Features i. You agree that a key feature of the LYNK application is that the LYNK Wallet may change over time, and this forms an important basis for granting you access to the LYNK application. Once we make changes to the LYNK Wallet, your continued use of the LYNK application will indicate your acceptance of any changes to the LYNK Wallet. You may stop using the LYNK Wallet and/or the LYNK application at any time. ii. Changes to the LYNK Wallet may affect your past activities on the LYNK Wallet and certain features ("Service Elements") that you may have used. Any changes to the LYNK Wallet may involve the deletion or resetting of your Service Elements. However, this will not affect your rights to the cryptocurrencies managed through the LYNK Wallet. iii. Blockchain technology is rapidly evolving, and we strive to keep the functionality of the LYNK app up to date with new developments. We may occasionally provide access to new compatible networks and features of the LYNK application that are still in "early access" or in the development and testing phase. iv. While we make every effort to ensure that any features we provide meet reasonable standards, these early access networks and features may have new and relatively untested characteristics, which means that you may encounter issues and the unavailability of the LYNK application more than other parts of the network. Therefore, we cannot guarantee that these beta features and networks will always be available, error-free, or operate smoothly. v. Refusing to make changes or updates to the LYNK application may expose your LYNK Wallet on your mobile device to higher security risks. You will also be unable to access any new features or upgraded functionalities that we may offer through the LYNK application. We are not liable for any losses incurred as a result of your failure to complete any updates to the LYNK application.`,
    noDes14: `E. Third-Party Applications and Exchanges`,
    exchanges1: `i. The LYNK Wallet allows you to access and use certain decentralized applications ("Dapps") and cryptocurrency platforms ("Crypto Platforms") that are integrated with or compatible with the LYNK app. These Dapps and cryptocurrency platforms are provided by third parties unrelated to LYNK. While we provide convenience in accessing these Dapps and cryptocurrency platforms, we are not responsible for them in any way. Your use of Dapps and cryptocurrency platforms is not governed by these Terms of Service and does not constitute a part of your relationship with LYNK. Before using their services through the LYNK application, you should ensure to review the terms and conditions of the Dapp or cryptocurrency platform provider's website for the latest versions.`,
    exchanges2: `ii. Not all cryptocurrency platforms and Dapps support all types of cryptocurrencies. Before initiating any transactions through the LYNK application, you should ensure that the cryptocurrencies you send are compatible with the recipient's wallet. You will not be able to retrieve any cryptocurrencies you have sent, and we are not responsible for any losses incurred due to incompatibility between the cryptocurrencies you sent and the recipient's wallet.`,
    exchanges3: `iii. Not all compatible networks support the same cryptocurrency platforms and Dapps. Before accessing or using them through the LYNK application, you should check which networks are compatible with the cryptocurrency platforms and Dapps you wish to use. Using cryptocurrency platforms and Dapps on networks different from the compatible network you set up for the LYNK Wallet may result in transaction failures, and you may also be unable to recover any cryptocurrencies you sent to those cryptocurrency platforms or Dapps. We are not responsible for any losses incurred due to using cryptocurrency platforms or Dapps on networks different from the compatible network you set up for the LYNK Wallet.`,
    noDes15: `F. Security i. If the security of the mobile device on which you install and set up the LYNK application is compromised, there may be risks to the cryptocurrencies and LYNK Wallet managed through the LYNK application. Therefore, you should ensure that your mobile device is secure by following best practices recommended by the operating system provider, such as setting a strong PIN and/or password to prevent unauthorized access and keeping your software up to date. ii. You are responsible for ensuring the security of your mobile device. Do not use the LYNK application on devices that have been "jailbroken," "rooted," or had software restrictions removed in any other way, as this may compromise the security of your LYNK Wallet and cryptocurrencies.`,
    no6: `Accessing the LYNK Wallet`,
    noDes16: `a. To use the LYNK Wallet, you must download and set up the LYNK application on your mobile device using a username and a numeric password. It is your responsibility to keep your password and any activities initiated through the LYNK Wallet on your mobile device confidential.`,
    noDes17: `b. Depending on the type of device you use, you may also configure the LYNK application to allow access to the LYNK Wallet using fingerprint and/or face ID. `,
    noDes18: `c. You must be at least 18 years old and legally capable of entering into a binding agreement in the country/region you reside in to use the LYNK Wallet.`,
    no7: `Setting Up Your LYNK Wallet`,
    noDes19: `a. When setting up your LYNK Wallet through the LYNK application, you will need to use the appropriate functionality to select the compatible networks you wish to access through the LYNK Wallet. We will indicate through the LYNK application which networks are accessible to you through the LYNK application.`,
    noDes20: `b. You can set up your LYNK Wallet to access multiple compatible networks. However, your cryptocurrencies will be stored and managed separately through individual encrypted wallets on each compatible network, and the cryptocurrencies managed on one compatible network will not be accessible on different compatible networks accessed through the LYNK application.`,
    noDes21: `c. For certain compatible networks identified on the LYNK Wallet, you can use the appropriate functionality of the LYNK application to move cryptocurrencies stored on one compatible network to another. However, please note that moving your cryptocurrencies in this manner will incur network fees, just like any other transactions on that network.`,
    no8: `Important Information about LYNK Wallet Recovery`,
    noDes22: `a. For security reasons, if you initiate the process to recover access to your cryptocurrencies, such as when you change or lose the mobile device on which you downloaded and installed the LYNK application, you will be unable to access your cryptocurrencies through the LYNK application for 48 hours after initiating the recovery process. We will not be liable for any losses you may incur during this period due to the inability to access your cryptocurrencies. `,
    noDes23: `b. If you change or lose the mobile device on which you downloaded and installed the LYNK application, you can regain access to your LYNK Wallet on a new device using a randomly generated unique recovery key ("offline recovery"), or for LYNK Wallets used to manage cryptocurrencies on Ethereum, by using one or more devices, individuals, or third-party services ("guardians") designated by you for this purpose. `,
    noDes24: `c. Offline Recovery i. To use offline recovery, you need to download and store the encrypted private key generated by the LYNK Wallet in iCloud or Google Drive on the mobile device on which you initially downloaded and set up the LYNK application. ii. When accessing the LYNK Wallet on another mobile device, you will be asked to complete an identity verification process using your email address or phone number and choose to restore your wallet using the encrypted private key stored in your iCloud or Google Drive. To do this, you need to be connected to the internet so that we can provide you with the decryption key that pairs with your encrypted private key. iii. You should ensure the secure retention of your encrypted private key and the security of your iCloud or Google Drive and mobile device. Unauthorized access to your iCloud or Google Drive and your encrypted private key may compromise the security of your LYNK Wallet, and if you lose your encrypted private key, you will be unable to use offline recovery to restore your LYNK Wallet. This may require you to use Ethereum recovery for your LYNK Wallet, which will incur network fees. We are not responsible for any losses you may incur due to the inability to access your encrypted private key, including any network fees you incur.`,
    noDes25: `d. Using Guardians i. When you first download and set up the LYNK application to access and manage transactions on Ethereum, you will be prompted to designate one or more guardians who will have the ability to lock or unlock your access to the cryptocurrencies you manage on Ethereum through the LYNK Wallet, restore access to those cryptocurrencies, and approve certain transactions initiated through the LYNK application. ii. Guardians are an important part of ensuring the security of the cryptocurrencies you manage through the LYNK application and are crucial for recovering access to those cryptocurrencies if you have not set up or cannot use offline recovery. Therefore, you should ensure that any guardians you designate are individuals, devices, or service providers that you trust and can contact if you need to lock, unlock, or recover access to the cryptocurrencies you manage through the LYNK application. You should always specify at least one guardian on the Ethereum LYNK Wallet. We also provide automated services that can act as your guardians. iii. We are not responsible for any losses you may incur due to the use of the LYNK application, including any failures or delays related to locking or unlocking your access to your cryptocurrencies or approving any transactions through the LYNK application.`,
    noDes26: `e. Using LYNK as Your Guardian i. If you manage cryptocurrencies on Ethereum using the LYNK application, our automated guardian feature allows you to lock, unlock, and recover access to those cryptocurrencies through two-factor authentication. If you use this feature, you must ensure the security of your email account and mobile device, as unauthorized access to your email account and the credentials you use to access and initiate transactions through the LYNK application may compromise the security of the automated guardian feature. ii. For security purposes, if LYNK is designated as your sole guardian, you will be unable to unlock your access to the LYNK Wallet locked with our automated guardian feature for 5 days. If you accidentally lock access to the LYNK Wallet and are unable to access your cryptocurrencies through the LYNK Wallet during this security period, we will not be liable for any losses you may incur.`,
    no9: `Your Rights to Use the LYNK Wallet`,
    noDes27: `a. The materials and content comprising the LYNK application and the LYNK Wallet are owned by us or our third-party licensors, and we grant you the right to use these materials and content solely for the purpose of using the LYNK application and the LYNK Wallet, subject to these terms.`,
    noDes28: `b. Your right to use the LYNK application and the LYNK Wallet is personal to you, and you may not grant this right to others.`,
    noDes29: `c. We grant you a non-exclusive, personal, non-transferable license to download the LYNK application onto your mobile device for the purpose of accessing and using the LYNK Wallet in accordance with these terms.`,
    noDes30: `d. Unless permitted by these terms and the functionality of the LYNK application, you agree not to: i. Copy any portion of the LYNK Wallet; ii. Provide, sell, or otherwise make available any portion of the LYNK Wallet to any other person; iii. Modify any portion of the LYNK Wallet in any way; iv. Reverse engineer or access the code of any portion of the LYNK Wallet that we have not explicitly made publicly available for general use.`,
    noDes31: `e. You agree that all confidential information, copyrights, and other intellectual property in the LYNK application and the LYNK Wallet belong to us or have been licensed to us by the respective rights holders.`,
    noDes32: `f. You agree that, except for the rights granted to you to use it under these terms, you have no rights to any portion of the LYNK application or the LYNK Wallet.`,
    no10: `Fees`,
    noDes33: `a. If you initiate transactions on certain compatible networks through the LYNK Wallet and complete them through third-party cryptocurrency platforms, we may charge you fees ("LYNK Fees").`,
    noDes34: `b. We will inform you whether LYNK Fees apply to a transaction and the amount of such fees before you confirm the transaction through the LYNK Wallet.`,
    noDes35: `c. We will require you to pay the LYNK Fees at the time you initiate the relevant transaction. If you refuse to pay the LYNK Fees or if you do not have sufficient cryptocurrency to cover the LYNK Fees and the transaction value, we will refuse to process the transaction through the LYNK Wallet.`,
    no11: `App Store Terms`,
    noDes36: `a. You acknowledge that these terms are the only terms and conditions governing our relationship with you and do not apply to your relationship with Apple Inc., Google Ireland Limited, or any other provider of the application store or distribution platform from which you downloaded the application ("Store Provider").`,
    noDes37: `b. We (not the Store Provider) are solely responsible for the performance of the LYNK application, any content we provide through the LYNK application, and any support or maintenance related to the LYNK application. The Store Provider has no obligation to provide any maintenance or support services for the LYNK application.`,
    noDes38: `c. If the LYNK application fails to conform to any warranties (including any regarding its functionality, suitability, or fitness for purpose) provided by us, we (not the Store Provider) will be solely responsible. To the maximum extent permitted by law, the Store Provider will have no liability for any claims relating to the LYNK application.`,
    noDes39: `d. If anyone makes a claim that the LYNK application infringes their intellectual property rights, we (not the Store Provider) will be solely responsible for investigating, defending, settling, or otherwise handling any such intellectual property infringement claim.`,
    noDes40: `e. You acknowledge that your country or region is not subject to U.S. government sanctions or designated as a "terrorist-supporting" country by the U.S. government, and you are not listed on any U.S. government's prohibited or restricted party lists.`,
    noDes41: `f. The Store Provider (and its affiliates) are third-party beneficiaries under these terms and have the right to enforce these terms against you.`,
    no12: `Acceptable Use Rules`,
    noDes42: `a. In addition to other requirements in these terms, this section sets out specific rules ("Acceptable Use Rules") that apply to your use of the LYNK application.`,
    noDes43: `b. When using the LYNK application, you must not: i. Circumvent, disable, or otherwise interfere with any security-related features of the LYNK application; ii. Allow others to use the LYNK application on your behalf unless they have obtained your authorization; iii. Use the LYNK application if we have suspended or banned you from using it; iv. Advocate, promote, or engage in any illegal or unlawful activities, including any criminal activities, fraud or money laundering, violations of sanctions, or actions that cause harm or damage to any person or property.`,
    noDes44: `c. We may monitor activity on the LYNK application to ensure compliance with the Acceptable Use Rules, including ensuring that the cryptocurrencies you manage are not purchased with unlawfully obtained funds.`,
    noDes45: `d. Non-compliance with the Acceptable Use Rules constitutes a serious breach of these terms and may result in us taking any or all of the following actions (with or without notice): i. Immediately, temporarily, or permanently withdrawing your use of the LYNK application; ii. Issuing a warning to you; iii. Taking legal action against you, including initiating litigation to recover all costs (including but not limited to reasonable administrative and legal costs) incurred as a result of the breach; iv. Disclosing to law enforcement authorities any information we reasonably believe to be necessary.`,
    noDes46: `e. The responses described in Section 12(d) are not exhaustive, and we may take any other actions that we deem appropriate.`,
    no13: `Termination of Our Relationship`,
    noDes47: `a. If at any time you believe you cannot agree to these terms or any changes made to these terms, you must immediately stop using the LYNK application.`,
    noDes48: `b. You may stop using the LYNK application at any time by removing it from the mobile device on which you downloaded it.`,
    noDes49: `c. We may also withdraw the LYNK application, provided we give you reasonable notice of our intention to do so.`,
    noDes50: `d. If you or we terminate your use of the LYNK application or if we withdraw the LYNK application as described in this section, you will lose any rights to use the LYNK application. This will not affect your rights to the cryptocurrencies you manage through the LYNK Wallet; however, the functionality facilitated by the LYNK application to process transactions related to those cryptocurrencies initiated through the LYNK application will cease. You can still access your cryptocurrencies by setting up transactions, downloading transaction data, and using that transaction data with an "emergency toolkit" through a different cryptocurrency wallet service provider.`,
    noDes51: `e. Termination of your use of the LYNK Wallet will not affect your obligation to pay us any amounts owed.`,
    noDes52: `f. Anything in this Section 13 does not affect any legal rights you may have under the laws of the country/region in which you reside, including (where applicable) your right to bring a claim in relation to your use of the LYNK Wallet or the LYNK application in the courts of that country/region. If you reside in Scotland or Northern Ireland, it also means that you can bring a claim in the courts of Scotland or Northern Ireland.`,
    no14: `Termination of Our Relationship`,
    noDes53: `a. If at any time you believe you cannot agree to these terms or any changes made to these terms, you must immediately stop using the LYNK application.`,
    noDes54: `b. You may stop using the LYNK application at any time by removing it from the mobile device on which you downloaded it.`,
    noDes55: `c. We may also withdraw the LYNK application, provided we give you reasonable notice of our intention to do so.`,
    noDes56: `d. If you or we terminate your use of the LYNK application or if we withdraw the LYNK application as described in this section, you will lose any rights to use the LYNK application. This will not affect your rights to the cryptocurrencies you manage through the LYNK Wallet; however, the functionality facilitated by the LYNK application to process transactions related to those cryptocurrencies initiated through the LYNK application will cease. You can still access your cryptocurrencies by setting up transactions, downloading transaction data, and using that transaction data with an "emergency toolkit" through a different cryptocurrency wallet service provider.`,
    noDes57: `e. Termination of your use of the LYNK Wallet will not affect your obligation to pay us any amounts owed.`,
    noDes58: `f. Anything in this Section 13 does not affect any legal rights you may have under the laws of the country/region in which you reside, including (where applicable) your right to bring a claim in relation to your use of the LYNK Wallet or the LYNK application in the courts of that country/region. If you reside in Scotland or Northern Ireland, it also means that you can bring a claim in the courts of Scotland or Northern Ireland.`,
    no15: `Our Liability/Obligations to You`,
    noDes59: `a. We make every effort to ensure that the features and functionality of the LYNK application meet reasonable standards and correspond to any descriptions we provide to you. However, since we do not own or produce the third-party applications, services, or networks that you can access through the LYNK Wallet, we cannot be held responsible for them in any way, nor can we guarantee that the LYNK Wallet will always be compatible with such third-party services.`,
    noDes60: `b. We will use our best efforts to ensure that you can always use any elements of the LYNK application that require an Internet connection and compatible networks. However, due to the nature of the internet, compatible networks, and general technology, any of these functionalities of the LYNK application may be unavailable if we need to perform any urgent or routine maintenance on our systems. This means that we cannot promise uninterrupted, delay-free, error-free, or expectation-compliant use of these functionalities of the LYNK application. To the extent permitted by law, we exclude any implied warranties.`,
    noDes61: `c. We are not responsible for any losses you may incur as a result of using the LYNK application or the LYNK Wallet, including any failures or delays related to locking, unlocking, or restoring your access to cryptographic assets or authorizations.`,
    noDes62: `d. If a claim arises from the provision of the LYNK application or the LYNK Wallet, our liability to you will never exceed the LYNK Fees you paid us for the transaction that caused the loss. If you have not paid us any LYNK Fees for that transaction, we will not be liable to you.`,
    noDes63: `e. The above does not affect any rights you may have under the applicable laws of your country/region, including our liability for any personal injury or death caused by our negligence or fraud.`,
    no16: `Changes to These Terms`,
    noDes64: `a. We may modify these terms from time to time.`,
    noDes65: `b. Changes typically occur when new features are added to the LYNK application or the LYNK Wallet, when there are changes in the law, or when we need to clarify our position on matters affecting these terms.`,
    noDes66: `c. Usually, we will try to give you some advance notice before the new terms take effect. However, there may be cases where immediate changes are necessary, and in such cases, we will not provide you with prior notice.`,
    no17: `Governing Documents for Our Relationship`,
    noDes67: `a. The current version of these terms contains the sole terms and conditions governing our relationship with you.`,
    noDes68: `b. We intend to rely on these terms as the written terms of our agreement with you regarding the provision of the LYNK Wallet and the LYNK application. If any part of these terms is unenforceable, the remaining parts of the terms will still apply to our relationship.`,
    noDes69: `c. If you fail to comply with these terms, and we do not take immediate action, it does not mean that we waive any rights we have. We may still take action in the future.`,
    no18: `Applicable Law`,
    noDes70: `a. English law applies to all disputes and the interpretation of these terms. The courts of England and Wales have non-exclusive jurisdiction over any dispute arising from or related to your use of the LYNK Wallet or the LYNK application.`,
    noDes71: `b. This does not affect any rights you may have under the laws of the country/region in which you reside, including (where applicable) your right to have disputes related to your use of the LYNK Wallet or the LYNK application heard in the courts of that country/region. If you reside in Scotland or Northern Ireland, it also means that you can bring a claim in the courts of Scotland or Northern Ireland.`,
    no19: `Contact, Feedback, and Complaints`,
    noDes72: `a. If you need to contact us regarding these terms or any other documents mentioned in them, please email us at bussiness@lynk.social.`,
    noDes73: `b. We value user opinions and are always interested in finding ways to improve the LYNK application or the LYNK Wallet. By providing your feedback, you agree to waive any rights you have in that feedback so that we may use it and allow others to use it without any restrictions and without paying you any fees.`,
    noDes74: `c. If you choose to contact us, we will handle any personal data you provide to us in accordance with our privacy policy.`,
  },
  footer: {
    getStarted: 'Ready to Get Started?',
    features: 'Features',
    buyAssets: 'Buy Assets',
    mobileWallet: 'Mobile Wallet',
    hardwareWallet: 'Hardware Wallet',
    pluginWallet: 'Plugin Wallet',
    developer: 'Developer',
    github: 'Github',
    devDocs: 'Dev Docs',
    submitApplication: 'Submit Application',
    company: 'Company',
    aboutUs: 'About Us',
    careers: 'Careers',
    brandMaterials: 'Brand Materials',
    support: 'Support',
    helpCenter: 'Help Center',
    contactUs: 'Contact Us',
    privacyPolicy: 'Privacy Policy',
    serviceAgreement: 'Service Agreement',
  },
};
