import { useState, useEffect } from 'react';
import { styled, Typography, Button, Stack, Box, Avatar, Rating } from '@mui/material';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Container from '@mui/material/Container';
import { useI18n } from 'react-simple-i18n';
import Carousel from 'react-material-ui-carousel';
import moment from 'moment';
import mobile1 from 'assets/home/mobile1.png';
import mobile2 from 'assets/home/mobile2.png';
import mobile3 from 'assets/home/mobile3.png';
import mobile4 from 'assets/home/mobile4.png';
import android from 'assets/home/android.png';
import appStore from 'assets/home/appStore.png';
import chrome from 'assets/home/chrome.png';
import googlePlay from 'assets/home/googlePlay.png';
import coins from 'assets/home/coins.png';
import ellipseH5Bg from 'assets/footer/ellipseH5Bg.png';
import mobileCoins from 'assets/home/mobileCoins.png';
import line from 'assets/home/line.png';
import security1 from 'assets/home/security1.png';
import security2 from 'assets/home/security2.png';
import security3 from 'assets/home/security3.png';
import securityWeb1 from 'assets/home/securityWeb1.png';
import securityWeb2 from 'assets/home/securityWeb2.png';
import securityWeb3 from 'assets/home/securityWeb3.png';
import step1Email from 'assets/home/step1Email.gif';
import step2Recover from 'assets/home/step2Recover.gif';
import step3Enable from 'assets/home/step3Enable.gif';
import user1 from 'assets/home/user1.png';
import user2 from 'assets/home/user2.png';
import user3 from 'assets/home/user3.png';
import useBreakpoint from 'hooks/useBreakpoint';
import useIntervalFrame from 'hooks/useIntervalFrame';
import Footer from 'components/Footer';
import { useNavigate, useLocation } from 'react-router-dom';

const HomeContentBox = styled('div')(({ theme }) => ({
  gridArea: 'home',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: 'linear-gradient(180deg, #FFF 0%, #F6FBFE 92.19%)',
}));

const HomeHeader = styled(Stack)(({ theme }) => ({
  minHeight: theme.spacing(86.5),
  height: `calc(100vh - ${theme.spacing(23.25)})`,
  padding: theme.spacing(8, 0, 10),
  backgroundImage: `url(${mobile1})`,
  backgroundPosition: 'right 52px',
  backgroundSize: '554px 566px',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5.5, 0, 0),
    height: theme.spacing(90.5),
    marginBottom: theme.spacing(3),
    backgroundPosition: 'center bottom',
    backgroundSize: '323px 330px',
  },
}));

const HomeHeaderInfo = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  width: theme.spacing(71),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    width: '100%',
    alignItems: 'center',
  },
}));

const HomeTitle = styled(Typography)(({ theme }) => ({
  fontSize: 64,
  fontWeight: 500,
  lineHeight: '70px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 'normal',
  },
}));

const HomeTitleDes = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
}));

const DownloadBox = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const DownloadBtn = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  borderRadius: theme.spacing(1.5),
  height: theme.spacing(7),
  width: theme.spacing(27),
  padding: theme.spacing(0, 0, 0, 1.5),
  textTransform: 'unset',
  border: 'none',
  background: '#fff',
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  '&:hover': {
    border: 'none',
    background: '#fff',
  },
  '& .appStore': {
    width: '30px',
    height: '37px',
  },
  '& .googlePlay': {
    width: '33px',
    height: '37px',
  },
  '& .chrome': {
    width: '43.45px',
    height: '36.8px',
  },
  '& .android': {
    width: '30.91px',
    height: '39px',
  },
  '& .where': {
    fontSize: 12,
    fontWeight: 400,
  },
  '& .store': {
    fontSize: 15,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    width: `calc(50% - ${theme.spacing(0.5)})`,
    boxShadow: '0px 2.7672955989837646px 13.836478233337402px 0px rgba(193, 193, 193, 0.25)',
    '& .appStore': {
      width: '20.8px',
      height: '25.6px',
    },
    '& .googlePlay': {
      width: '22.9px',
      height: '25.6px',
    },
    '& .chrome': {
      width: '30.1px',
      height: '25.5px',
    },
    '& .android': {
      width: '21.4px',
      height: '27px',
    },
    '& .where,.store': {
      zoom: 0.8,
    },
  },
}));

const ChainNetworks = styled(Stack)(({ theme }) => ({
  height: theme.spacing(14.25),
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  background: '#0096C7',
  backgroundImage: `url(${ellipseH5Bg})`,
  backgroundPosition: `calc(100%) 0`,
  backgroundSize: `calc(70%) 447px`,
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(39),
    flexDirection: 'column',
    backgroundPosition: `calc(100%) bottom`,
    backgroundSize: `380px 315px`,
  },
}));

const Coins = styled(Avatar)(({ theme }) => ({
  height: '46.5px',
  width: theme.spacing(53),
  [theme.breakpoints.down('sm')]: {
    height: '88.58px',
    width: '257px',
  },
}));

const CoinsText = styled(Box)(({ theme }) => ({
  width: theme.spacing(25),
  height: theme.spacing(8),
  fontSize: 20,
  color: '#fff',
  fontWeight: 500,
  lineHeight: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

const ExploreAll = styled(Button)(({ theme }) => ({
  background: '#fff',
  border: 'none',
  '&:hover': {
    background: '#fff',
    border: 'none',
  },
  lineHeight: theme.spacing(4),
}));

const MpcSecurity = styled(Stack)(({ theme }) => ({
  height: theme.spacing(108),
  alignItems: 'center',
  gap: theme.spacing(4.5),
  padding: theme.spacing(12, 0, 0),
  '& .securityTitle': {
    fontSize: 40,
  },
  '& .learnMoreH5': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(41),
    padding: theme.spacing(8, 0, 0),
    flexDirection: 'column',
    gap: theme.spacing(0),
    '& .securityTitle': {
      fontSize: 24,
    },
    '& .securityDes,.learnMore': {
      display: 'none',
    },
    '& .learnMoreH5': {
      marginTop: 'auto',
      display: 'flex',
      height: theme.spacing(4),
      fontWeight: 400,
      alignSelf: 'flex-start',
    },
  },
}));

const MpcSecurityDes = styled(Stack)(({ theme }) => ({
  height: theme.spacing(66.5),
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing(7),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(0),
    marginTop: theme.spacing(4),
  },
}));

const MpcSecurityLeft = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: theme.spacing(58.5),
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: theme.spacing(51),
  },
}));

const StepContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  top: 'calc(50% - 85.5px)',
  width: 29.5,
  height: 180,
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(3),
  },
}));

const MpcSecurityRight = styled(Stack)(({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  gap: theme.spacing(4.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginTop: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
  },
}));

const SecurityItem = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
  [theme.breakpoints.up('sm')]: {
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
    height: theme.spacing(53.5),
    padding: theme.spacing(9.5, 2, 0),
    alignItems: 'center',
    flex: 1,
    '& .title': {
      textAlign: 'center',
      fontSize: 22,
    },
    '& .des': {
      textAlign: 'center',
    },
    '& .MuiAvatar-root': {
      height: theme.spacing(11),
      width: theme.spacing(11),
      background: '#B0E3FF',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    '& img': {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    '& .title': {
      fontSize: 18,
    },
    '& .des': {
      fontSize: 12,
    },
  },
}));

const StepBox = styled(Box)<{ checked: string }>(({ theme, checked }) => ({
  height: '29.5px',
  width: '29.5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '14.75px',
  background: checked ? 'linear-gradient(91deg, #438FD6 0%, #0096C7 100%)' : '#fff',
  color: checked ? '#fff' : '#0096C7',
  boxShadow: checked ? 'unset' : '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
}));

const Line = styled('img')(({ theme }) => ({
  height: theme.spacing(6),
}));

const DefiBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(75),
  justifyContent: 'center',
  backgroundImage: `url(${mobile2})`,
  backgroundPosition: 'right center',
  backgroundSize: '587px 600px',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(89.5),
    padding: theme.spacing(9.35, 0),
    backgroundPosition: `center ${theme.spacing(18.75)}`,
    backgroundSize: '100% 378px',
  },
}));

const StackText = styled(Stack)(({ theme }) => ({
  width: '502px',
  gap: theme.spacing(2),
  '& button': {
    width: 'fit-content',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'unset',
    '& button': {
      display: 'none',
    },
  },
}));

const CrossChain = styled(Stack)(({ theme }) => ({
  height: theme.spacing(86.75),
  padding: theme.spacing(8, 0),
  justifyContent: 'center',
  backgroundImage: `url(${mobile1})`,
  backgroundPosition: 'left center',
  backgroundSize: '554px 570px',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  '& .viewTokensH5': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(75),
    padding: theme.spacing(0),
    backgroundPosition: `center ${theme.spacing(9.5)}`,
    backgroundSize: '100% 378px',
    '& .viewTokensH5': {
      display: 'flex',
      fontWeight: 400,
      width: 'fit-content',
      paddingLeft: 0,
    },
  },
}));

const NFTManagement = styled(Stack)(({ theme }) => ({
  height: theme.spacing(79.25),
  padding: theme.spacing(0, 0, 12),
  justifyContent: 'center',
  backgroundImage: `url(${mobile3})`,
  backgroundPosition: 'right top',
  backgroundSize: '510.5px 538px',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(82),
    padding: theme.spacing(9.5, 0),
    backgroundPosition: `center ${theme.spacing(19)}`,
    backgroundSize: '100% 364px',
  },
}));

const UserBox = styled(Stack)(({ theme }) => ({
  height: theme.spacing(111),
  padding: theme.spacing(12.5, 0),
  width: '100%',
  background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(85),
    padding: theme.spacing(1, 0, 0),
  },
}));

const UserCard = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  gap: theme.spacing(6),
  alignItems: 'center',
  borderRadius: theme.spacing(1.5),
  background: '#fff',
  height: theme.spacing(59.5),
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.25)',
  '& .user': {
    height: '54px',
    width: '54px',
  },
  [theme.breakpoints.up('sm')]: {
    flex: 1,
    minWidth: theme.spacing(44),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: theme.spacing(54.5),
    padding: theme.spacing(4),
    gap: theme.spacing(4),
    boxShadow: '0px 3.7493252754211426px 18.746625900268555px 0px rgba(0, 0, 0, 0.25)',
    '& .user': {
      height: '50px',
      width: '50px',
    },
  },
}));

const HomeContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useBreakpoint();
  // const [gifIndex, setGifIndex] = useState(1);
  // const [gifEndTime, setGifEndTime] = useState(0);
  // const { timeInfo } = useIntervalFrame({
  //   delay: 1000,
  //   endTime: gifEndTime,
  //   endTimeOut: () => {
  //     if (gifIndex === 1) {
  //       setGifIndex(2);
  //       setGifEndTime(moment().add(9, 'seconds').valueOf());
  //     } else if (gifIndex === 2) {
  //       setGifIndex(3);
  //       setGifEndTime(moment().add(15, 'seconds').valueOf());
  //     } else if (gifIndex === 3) {
  //       setGifIndex(1);
  //       setGifEndTime(moment().add(6, 'seconds').valueOf());
  //     }
  //   },
  // });

  // useEffect(() => {
  //   setGifIndex(1);
  //   setGifEndTime(moment().add(6, 'seconds').valueOf());
  // }, []);

  const securityDesList = [
    {
      title: t('home.mpcSecurityList1'),
      des: t('home.mpcSecurityList2'),
      icon: security1,
      iconWeb: securityWeb1,
    },
    {
      title: t('home.mpcSecurityList3'),
      des: t('home.mpcSecurityList4'),
      icon: security2,
      iconWeb: securityWeb2,
    },
    {
      title: t('home.mpcSecurityList5'),
      des: t('home.mpcSecurityList6'),
      icon: security3,
      iconWeb: securityWeb3,
    },
  ];

  const userList = [
    {
      name: 'Jake.L',
      des: t('home.user1'),
      icon: user1,
    },
    {
      name: 'Mandy.C',
      des: t('home.user2'),
      icon: user2,
    },
    {
      name: 'Jackson.J',
      des: t('home.user3'),
      icon: user3,
    },
  ];

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    if (pathname === '/features') {
      scrollToAnchor('featrue');
    }
  }, [pathname]);

  return (
    <HomeContentBox>
      <Container>
        <HomeHeader>
          <HomeHeaderInfo>
            <Typography fontSize={14} fontWeight={700} color={'#0096C7'}>
              {t('home.pageDes')}
            </Typography>
            <HomeTitle>{t('home.title')}</HomeTitle>
            <HomeTitleDes variant="body2">{t('home.titleDes')}</HomeTitleDes>
            <DownloadBox>
              <DownloadBtn onClick={() => navigate('/login')}>
                <Avatar alt="App Store" className="appStore" src={appStore} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download1')}</Typography>
                  <Typography className="store">{t('home.download2')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Google Play" className="googlePlay" src={googlePlay} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download3')}</Typography>
                  <Typography className="store">{t('home.download4')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Chrome" className="chrome" src={chrome} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download5')}</Typography>
                  <Typography className="store">{t('home.download6')}</Typography>
                </Stack>
              </DownloadBtn>
              <DownloadBtn variant="outlined" onClick={() => navigate('/login')}>
                <Avatar alt="Android" className="android" src={android} variant="rounded" />
                <Stack alignItems={'flex-start'}>
                  <Typography className="where">{t('home.download7')}</Typography>
                  <Typography className="store">{t('home.download8')}</Typography>
                </Stack>
              </DownloadBtn>
            </DownloadBox>
          </HomeHeaderInfo>
        </HomeHeader>
      </Container>
      <ChainNetworks>
        <Coins alt="coins" src={isMobile ? mobileCoins : coins} variant="rounded" />
        <CoinsText>{t('home.networks')}</CoinsText>
        <ExploreAll
          variant="outlined"
          endIcon={<ArrowOutwardOutlinedIcon />}
          onClick={() => navigate('/ecosystem/token-list')}
        >
          {t('home.exploreAll')}
        </ExploreAll>
      </ChainNetworks>
      <Container>
        <MpcSecurity id="featrue">
          <Stack alignItems={'center'}>
            <Typography className="securityTitle">{t('home.mpcSecurityTitle')}</Typography>
            <Typography className="securityDes" variant="body2" textAlign={'center'}>
              {t('home.mpcSecurityDes')}
            </Typography>
          </Stack>
          <Button
            className="learnMore"
            onClick={() => navigate('/about-us/technology')}
            variant="contained"
            endIcon={<ArrowOutwardOutlinedIcon />}
          >
            {t('home.learnMore')}
          </Button>
          <MpcSecurityDes>
            {/* <MpcSecurityLeft>
              <Avatar
                sx={{ width: '100%', height: '100%' }}
                src={gifIndex === 1 ? step1Email : gifIndex === 2 ? step2Recover : step3Enable}
              />
              <StepContainer>
                <StepBox checked={gifIndex === 1 ? 'checked' : ''}>1</StepBox>
                <Line src={line} />
                <StepBox checked={gifIndex === 2 ? 'checked' : ''}>2</StepBox>
                <Line src={line} />
                <StepBox checked={gifIndex === 3 ? 'checked' : ''}>3</StepBox>
              </StepContainer>
            </MpcSecurityLeft> */}
            <MpcSecurityRight>
              {securityDesList.map((item) => (
                <SecurityItem key={item.title}>
                  <Avatar src={isMobile ? item.icon : item.iconWeb} />
                  <Stack>
                    <Typography className="title">{item.title}</Typography>
                    <Typography className="des" variant="body2">
                      {item.des}
                    </Typography>
                  </Stack>
                </SecurityItem>
              ))}
            </MpcSecurityRight>
          </MpcSecurityDes>
          <Button
            className="learnMoreH5"
            onClick={() => navigate('/about-us/technology')}
            variant="text"
            endIcon={<ArrowForwardOutlinedIcon />}
          >
            {t('home.learnMore')}
          </Button>
        </MpcSecurity>
        <DefiBox>
          <StackText>
            <Typography>{t('home.deFiIntegrations')}</Typography>
            <Typography variant="body2">{t('home.deFiIntegrationsDes')}</Typography>
          </StackText>
        </DefiBox>
        <CrossChain>
          <StackText alignSelf={'flex-end'}>
            <Typography>{t('home.crossChain')}</Typography>
            <Typography variant="body2">{t('home.crossChainDes')}</Typography>
            <Button variant="contained" endIcon={<ArrowOutwardOutlinedIcon />}>
              {t('home.viewTokens')}
            </Button>
          </StackText>
          <Button variant="text" className="viewTokensH5" endIcon={<ArrowForwardOutlinedIcon />}>
            {t('home.viewTokens')}
          </Button>
        </CrossChain>
        <NFTManagement>
          <StackText>
            <Typography>{t('home.nftManagement')}</Typography>
            <Typography variant="body2">{t('home.nftManagementDes')}</Typography>
          </StackText>
        </NFTManagement>
      </Container>
      <UserBox>
        <Container>
          <Stack sx={(theme) => ({ gap: theme.spacing(8), [theme.breakpoints.down('sm')]: { gap: theme.spacing(5) } })}>
            <Stack gap={2} alignItems={'center'}>
              <Typography fontSize={14} textAlign={'center'} color={'#0096C7'}>
                {t('home.userReviews')}
              </Typography>
              <Typography textAlign={'center'}>{t('home.userReviewsNum')}</Typography>
              <Typography
                variant="body2"
                textAlign={'center'}
                sx={(theme) => ({ [theme.breakpoints.down('sm')]: { display: 'none' } })}
              >
                {t('home.userReviewsDes')}
              </Typography>
            </Stack>
            {isMobile ? (
              <Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Carousel
                  autoPlay={false}
                  indicators={true}
                  animation="slide"
                  cycleNavigation={false}
                  navButtonsProps={{
                    style: {
                      backgroundColor: 'transparent',
                      color: '#121425',
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {
                      color: '#0096C7',
                    },
                  }}
                  indicatorIconButtonProps={{
                    style: {
                      color: '#D9D9D9',
                    },
                  }}
                  sx={() => ({ width: '100%', position: 'unset' })}
                >
                  {userList.map((item) => (
                    <Stack width={'100%'} height={450} padding={2} key={item.name}>
                      <UserCard>
                        <Stack gap={2} alignItems={'center'}>
                          <Avatar className="user" src={item.icon} variant="rounded" />
                          <Typography fontSize={18}>{item.name}</Typography>
                          <Rating sx={{ color: '#0096C7' }} value={5} readOnly />
                        </Stack>
                        <Typography fontSize={18} color={'#0E0637'} textAlign={'center'}>
                          {item.des}
                        </Typography>
                      </UserCard>
                    </Stack>
                  ))}
                </Carousel>
              </Stack>
            ) : (
              <Stack direction={'row'} gap={4} justifyContent={'space-between'}>
                {userList.map((item) => (
                  <UserCard key={item.des}>
                    <Stack gap={2} alignItems={'center'}>
                      <Avatar className="user" src={item.icon} variant="rounded" />
                      <Typography fontSize={18}>{item.name}</Typography>
                      <Rating sx={{ color: '#0096C7' }} value={5} readOnly />
                    </Stack>
                    <Typography fontSize={18} color={'#0E0637'} textAlign={'center'}>
                      {item.des}
                    </Typography>
                  </UserCard>
                ))}
              </Stack>
            )}
          </Stack>
        </Container>
      </UserBox>
      <Footer />
    </HomeContentBox>
  );
};

export default HomeContent;
