import { useState } from 'react';
import { styled, Typography, Stack, InputBase, Avatar, Container, Paper, IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { lists } from './config';
import ExpandMore from '@mui/icons-material/KeyboardArrowDownOutlined';
import ExpandLess from '@mui/icons-material/KeyboardArrowUpOutlined';
import { ReactComponent as Search } from 'assets/tokenList/search.svg';
import { useI18n } from 'react-simple-i18n';
import useBreakpoint from 'hooks/useBreakpoint';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const TokenListContentBox = styled('div')(({ theme }) => ({
  gridArea: 'tokenList',
  display: 'grid',
  gridTemplateColumns: '1fr',
  background: '#fff',
}));

const SupportedListBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(9.5, 0),
  background: '#F8FDFF',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4.5, 0),
    background: '#fff',
  },
}));

const SupportedList = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(7),
  '& .tokens': {
    color: '#0096C7',
    fontSize: 18,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(5),
    '& .tokens': {
      fontWeight: 400,
    },
  },
}));

const TitleBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  '& .titleInfo': {
    color: '#1A191E',
    fontSize: 20,
    fontWeight: 500,
  },
  '& .title': {
    color: '#1A191E',
    fontSize: 40,
    fontWeight: 500,
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    '& .titleInfo': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 14,
      fontWeight: 700,
    },
    '& .title': {
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'center',
      width: '80%',
      fontSize: 24,
      fontWeight: 700,
    },
  },
}));

const ListBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  borderRadius: '25px',
  gap: theme.spacing(4),
  padding: theme.spacing(3),
  background: '#fff',
  border: '1px solid #D8D8D8',
  minWidth: '1152px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset',
    padding: theme.spacing(0),
    border: 'unset',
    borderRadius: 'unset',
  },
}));

const ListHeader = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(9.5),
  alignItems: 'center',
  flexDirection: 'row',
  '& p': {
    color: '#0096C7',
    fontSize: 22,
    fontWeight: 500,
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
    height: 'unset',
    '& p': {
      fontSize: 14,
      fontWeight: 700,
    },
    '& p:nth-child(2)': {
      textAlign: 'right',
    },
  },
}));

const SearchPaper = styled(Paper)(({ theme }) => ({
  p: '2px 28px',
  borderRadius: '25px',
  border: '1px solid #D8D8D8',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '75px',
  [theme.breakpoints.down('sm')]: {
    height: '44px',
    borderRadius: '8px',
    '& button': {
      width: '44px',
      height: '44px',
      marginRight: '10px',
    },
  },
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  ml: 1,
  flex: 1,
  color: '#ACA6A6',
  fontSize: 18,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2.5),
    fontSize: 12,
    fontWeight: 400,
  },
}));

const ChainListBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    // height: theme.spacing(85),
    // padding: theme.spacing(1, 0, 0),
  },
}));

const ChainBox = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  '& .icon': {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  '& p': {
    color: '#393C4E',
    fontSize: 18,
    fontWeight: 700,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& .icon': {
      width: theme.spacing(5.5),
      height: theme.spacing(5.5),
    },
    '& p': {
      fontSize: 17,
    },
  },
}));

const ChainListStack = styled(Stack)(() => ({
  flex: 3,
}));

const ListStack = styled(Stack)(() => ({
  flexDirection: 'row',
  flex: 1,
  alignItems: 'center',
}));

const ChainList = styled(Stack)(({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  flexWrap: 'wrap',
  '&.ChainListChildren': {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    background: '#F8F8F8',
    borderRadius: theme.spacing(1.5),
  },
}));

const ChainItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  borderRadius: '7px',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  alignItems: 'center',
  boxShadow: '0px 4px 20px 0px rgba(193, 193, 193, 0.25)',
  '& .chainIcon': {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  '& p': {
    color: '#393C4E',
    fontSize: 12,
    fontWeight: 500,
  },
}));

const TokenListContent = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const isMobile = useBreakpoint();
  const [chainLists, setChainLists] = useState(lists);

  const changeCollapse = (index: number, collapse: boolean) => {
    const newChainLists = chainLists.map((item, i) => {
      if (index === i) {
        item.collapse = collapse;
        return item;
      } else {
        return item;
      }
    });
    setChainLists(newChainLists);
  };

  return (
    <TokenListContentBox>
      <BaseHeader
        pageDes={t('ecosystem.title')}
        title={t('ecosystem.title')}
        titleDes={t('ecosystem.titleDes')}
        className="tokenList"
      />
      <SupportedListBox>
        <Container>
          <SupportedList>
            <TitleBox>
              <Typography className="titleInfo">{t('ecosystem.supportedList')}</Typography>
              <Typography className="title">{t('ecosystem.supportedListDes')}</Typography>
            </TitleBox>
            <ListBox>
              {/* <SearchPaper elevation={0}>
                <SearchInput placeholder={t('ecosystem.searchPlh')} inputProps={{ 'aria-label': 'search' }} />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <Search />
                </IconButton>
              </SearchPaper> */}
              <ListHeader>
                <Typography sx={{ flex: 1 }}>{t('ecosystem.coins')}</Typography>
                <Typography sx={{ flex: 3 }}>{t('ecosystem.chains')}</Typography>
              </ListHeader>
              <ChainListBox>
                {chainLists.map(({ title, icon, children, collapse }, index) => {
                  return (
                    <Stack key={title} direction={isMobile ? 'column' : 'row'} alignItems={'flex-start'}>
                      <ChainBox onClick={() => changeCollapse(index, !collapse)}>
                        <Avatar className="icon" src={icon} />
                        <Typography>{title}</Typography>
                        {!isMobile ? null : collapse ? (
                          <ExpandLess sx={{ cursor: 'pointer', marginLeft: 'auto' }} />
                        ) : (
                          <ExpandMore sx={{ cursor: 'pointer', marginLeft: 'auto' }} />
                        )}
                      </ChainBox>
                      {isMobile ? (
                        <Collapse in={collapse} timeout={'auto'} sx={{ width: '100%' }} unmountOnExit>
                          <ChainList>
                            <Typography width={'100%'} color={'#0096C7'} fontSize={14}>
                              {t('ecosystem.chains')}
                            </Typography>
                            {children.map((el, i) => (
                              <ChainItem key={i}>
                                <Avatar className="chainIcon" src={el.chainIcon} />
                                <Typography>{el.name}</Typography>
                              </ChainItem>
                            ))}
                          </ChainList>
                        </Collapse>
                      ) : (
                        <ChainListStack>
                          <ListStack>
                            <ChainList>
                              {children.slice(0, 7).map((el, i) => (
                                <ChainItem key={i}>
                                  <Avatar className="chainIcon" src={el.chainIcon} />
                                  <Typography>{el.name}</Typography>
                                </ChainItem>
                              ))}
                            </ChainList>
                            {children.length < 8 ? null : collapse ? (
                              <ExpandLess sx={{ cursor: 'pointer' }} onClick={() => changeCollapse(index, false)} />
                            ) : (
                              <ExpandMore sx={{ cursor: 'pointer' }} onClick={() => changeCollapse(index, true)} />
                            )}
                          </ListStack>
                          {children.length > 7 ? (
                            <Collapse in={collapse} timeout={'auto'} unmountOnExit>
                              <ChainList className="ChainListChildren">
                                {children.slice(7, children.length).map((el, i) => (
                                  <ChainItem key={i}>
                                    <Avatar className="chainIcon" src={el.chainIcon} />
                                    <Typography>{el.name}</Typography>
                                  </ChainItem>
                                ))}
                              </ChainList>
                            </Collapse>
                          ) : null}
                        </ChainListStack>
                      )}
                    </Stack>
                  );
                })}
              </ChainListBox>
            </ListBox>
            <Typography className="tokens">{t('ecosystem.tokens')}</Typography>
          </SupportedList>
        </Container>
      </SupportedListBox>
      <Footer />
    </TokenListContentBox>
  );
};

export default TokenListContent;
