import { FC, PropsWithChildren, useEffect } from 'react';
import { styled, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Content = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'grid',
  background: '#fff',
  gridTemplateRows: `1fr`,
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'layoutC'
  `,
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100%)',
    width: 'calc(100%)',
  },
  overflow: 'overlay',
}));

const LayoutContent = styled(Box)({
  gridArea: 'layoutC',
});

const Layout: FC<PropsWithChildren> = () => {
  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    documentHeight();
    window.addEventListener('resize', documentHeight);
  }, []);

  return (
    <Content>
      <LayoutContent>
        <Outlet />
      </LayoutContent>
    </Content>
  );
};
export default Layout;
