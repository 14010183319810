import { useState } from 'react';
import { styled, Typography, Stack, Button, Collapse } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useBreakpoint from 'hooks/useBreakpoint';

const PageFooterBox = styled(Stack)<{ ispadding: string }>(({ theme, ispadding }) => ({
  // height: theme.spacing(134),
  padding: theme.spacing(12, 0),
  width: '100%',
  gap: theme.spacing(8),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    gap: theme.spacing(5.5),
    // height: ispadding ? theme.spacing(82) : theme.spacing(70.5),
    padding: ispadding ? theme.spacing(11.5, 0, 8) : theme.spacing(0, 0, 8),
    '& button': {
      alignSelf: 'start',
      fontSize: 16,
      fontWeight: 400,
    },
  },
}));

const AskedQuestions = styled(Stack)(({ theme }) => ({
  flex: 1,
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(3),
  },
}));

const AskedQuestionsBox = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}));

const AskedQuestionsItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .num': {
    color: '#0096C7',
  },
  '& .questions': {
    fontSize: 24,
  },
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    '& .num': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20px',
    },
    '& .questions': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
}));

const TitleTop = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  '& .title': {
    fontSize: 14,
    color: '#0096C7',
    fontWeight: 500,
  },
  '& .des': {
    fontSize: 40,
    fontWeight: 500,
  },
  [theme.breakpoints.down('sm')]: {
    // height: theme.spacing(85),
    padding: theme.spacing(1, 0, 0),
    '& .title': {
      fontWeight: 700,
    },
    '& .des': {
      fontSize: 24,
      fontWeight: 700,
      width: '90%',
      textAlign: 'center',
    },
  },
}));

const PageFooter = ({ isPadding, id }: { isPadding?: boolean; id?: string }) => {
  const { t } = useI18n();
  const isMobile = useBreakpoint();
  const lists = [
    {
      number: '01',
      questions: 'aboutUs.askedQuestions1',
      asked: 'aboutUs.askedQuestionsDes1',
      collapse: false,
    },
    {
      number: '02',
      questions: 'aboutUs.askedQuestions2',
      asked: 'aboutUs.askedQuestionsDes2',
      collapse: false,
    },
    {
      number: '03',
      questions: 'aboutUs.askedQuestions3',
      asked: 'aboutUs.askedQuestionsDes3',
      collapse: false,
    },
    {
      number: '04',
      questions: 'aboutUs.askedQuestions4',
      asked: 'aboutUs.askedQuestionsDes4',
      collapse: false,
    },
    {
      number: '05',
      questions: 'aboutUs.askedQuestions5',
      asked: 'aboutUs.askedQuestionsDes5',
      collapse: false,
    },
  ];
  const [questionsList, setQuestionsList] = useState(lists);

  const changeCollapse = (index: number, collapse: boolean) => {
    const newChainLists = questionsList.map((item, i) => {
      if (index === i) {
        item.collapse = collapse;
        return item;
      } else {
        return item;
      }
    });
    setQuestionsList(newChainLists);
  };

  return (
    <PageFooterBox id={id} ispadding={isPadding ? 'ispadding' : ''}>
      <TitleTop>
        <Typography className="title">{t('aboutUs.faq')}</Typography>
        <Typography className="des">{t('aboutUs.askedQuestionsTitle')}</Typography>
      </TitleTop>
      <AskedQuestions>
        {questionsList.map((item, index) => (
          <AskedQuestionsBox key={item.number}>
            <AskedQuestionsItem>
              <Stack direction={'row'} gap={2} alignItems={isMobile ? 'flex-start' : 'center'}>
                <Typography className="num">{item.number}</Typography>
                <Typography className="questions">{t(item.questions)}</Typography>
              </Stack>
              {item.collapse ? (
                <RemoveOutlinedIcon
                  sx={{ fontSize: isMobile ? 18 : 36, cursor: isMobile ? 'unset' : 'pointer' }}
                  onClick={() => changeCollapse(index, !item.collapse)}
                />
              ) : (
                <AddOutlinedIcon
                  sx={{ fontSize: isMobile ? 18 : 36, cursor: isMobile ? 'unset' : 'pointer' }}
                  onClick={() => changeCollapse(index, !item.collapse)}
                />
              )}
            </AskedQuestionsItem>
            <Collapse in={item.collapse} timeout={'auto'} unmountOnExit>
              <Stack pl={isMobile ? 4.5 : 6} pr={isMobile ? 4 : 6}>
                <Typography variant="body2" fontSize={isMobile ? 12 : 18}>
                  {t(item.asked)}
                </Typography>
              </Stack>
            </Collapse>
          </AskedQuestionsBox>
        ))}
      </AskedQuestions>
      <Button
        variant={isMobile ? 'text' : 'contained'}
        endIcon={isMobile ? <ArrowForwardOutlinedIcon /> : <ArrowOutwardOutlinedIcon />}
      >
        {t('aboutUs.showMore')}
      </Button>
    </PageFooterBox>
  );
};

export default PageFooter;
